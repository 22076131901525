import TWEEN from '@tweenjs/tween.js';
import {MeshStandardMaterial, CanvasTexture, Color, sRGBEncoding} from 'three';
import {Season} from '../../Enum/Enums';
import SeasonLeavesColors from './SeasonLeavesColors';
import Utils from '../../utils';


class LeavesMaterialController{
  canvas : HTMLCanvasElement = document.createElement("canvas");
  c : any;
  store : any;
  
  leavesMaterial : MeshStandardMaterial = new MeshStandardMaterial({
    //color :0xffffff,
    map : new CanvasTexture(this.canvas),
    roughness: 1,
    metalness: 0,
    emissive : new Color(0x000000)

  });



  fieldSize : number = 32;
  fieldGridCount : number = 3;

  colors : any = SeasonLeavesColors;
  

  constructor(props){
    this.store = props.store;

    this.canvas.width = this.fieldSize * this.fieldGridCount;
    this.canvas.height = this.fieldSize * this.fieldGridCount;
    this.canvas.style.position = "absolute";
    this.canvas.style.top = "0px";
    this.canvas.style.zIndex = "-2";
    this.canvas.style.display = "none";

    this.canvas.style.width = this.canvas.width + "px";
    this.canvas.style.height = this.canvas.height + "px";

    this.c = this.canvas.getContext("2d");

    var season = this.store.state.area.season;

    this.SetCanvasColors(this.colors[season]);

    this.canvas.classList.add("leaves-canvas");
    document.body.appendChild(this.canvas);

    this.leavesMaterial.name = "LeavesMaterial";
    this.leavesMaterial.map.encoding = sRGBEncoding;
    

    this.store.watch(state => state.area.season, (newValue, oldValue) => {
      this.AnimateColors(this.colors[oldValue],this.colors[newValue]);
    });
  }

  GetMaterial(mat){
    
    this.leavesMaterial.aoMap = mat.aoMap;
    return this.leavesMaterial;
  }

  SetCanvasColors(colors){
    var colorIndex = 0;
    
    for(var y=0;y<this.fieldGridCount;y++){
      for(var x=0;x<this.fieldGridCount;x++){

        this.c.fillStyle = colors[colorIndex];

        this.c.fillRect(x * this.fieldSize,y * this.fieldSize,this.fieldSize ,this.fieldSize );
      
        colorIndex++;
      }
    }
  }

  AnimateColors(sColor, eColor){
    var startColor = [...sColor];
    var endColor = [...eColor];

    new TWEEN.Tween({
      alpha : 0
    })
    .to({
      alpha : 1
    }, 1000)
    .easing(TWEEN.Easing.Quadratic.Out)
    .onUpdate((v) => {

      var resultColors = startColor.map((color,index) => {
        return Utils.lerpColor(color, endColor[index] , v.alpha);
      })

      this.SetCanvasColors(resultColors);
      
      if(this.leavesMaterial.map != null){
        this.leavesMaterial.map.needsUpdate = true;
      }
    }).start();
  }

//   /**
//  * A linear interpolator for hexadecimal colors
//  * @param {String} a
//  * @param {String} b
//  * @param {Number} amount
//  * @example
//  * // returns #7F7F7F
//  * lerpColor('#000000', '#ffffff', 0.5)
//  * @returns {String}
//  */

//   lerpColor(a, b, amount) { 

//     var ah = +a.replace('#', '0x'),
//         ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
//         bh = +b.replace('#', '0x'),
//         br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
//         rr = ar + amount * (br - ar),
//         rg = ag + amount * (bg - ag),
//         rb = ab + amount * (bb - ab);

//     return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
//   }

}

export default LeavesMaterialController;