import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class Battery implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups =  ["battery"];
    Xr: any;
    Store: any;

    constructor(xr: any, store:any){
      this.Xr = xr;
      this.Store = store;
      this.Init();
      this.Models = [];
    }

    Init(){
      this.Store.watch(state => state.area.battery,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{
      
        this.Models.forEach(model=>{
          var enabled = model.userData.ConfigValue == newVal;

          model.material.transparent = !enabled;
          model.material.opacity = enabled ? 1 : .2;
          model.castShadow = enabled;

        });
    }

    AddModels(model: Object3D): void {

      this.Models.push(model);
        
      this.HandleConfigChange("Battery", null);
    }

    GetModels(): Object3D[] {
      return this.Models;
    }
}

export default Battery;