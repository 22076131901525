import mainconfig from '../../main.config';
import { Room } from '../Enum/Enums';
const LoadStack = [

  {
    url:'models/rooms/tiny_city/tiny_city_reduced.glb',
    name: "TinyCity",
    rooms : [Room.TinyCity]
  },
  // InstallationsRoom
  {
    url: 'models/rooms/installationsRoom/installations_room.glb',
    name: "InstallationsRoom",
    rooms : [Room.InstallationsRoom]
  },{
    url: 'models/rooms/installationsRoom/battery.glb',
    name: "IR_Battery",
    rooms : [Room.InstallationsRoom]
  },{
    url: 'models/rooms/installationsRoom/inverter.glb',
    name: "IR_Inverter",
    rooms : [Room.InstallationsRoom]
  },{
    url: 'models/rooms/installationsRoom/pump.glb',
    name: "IR_Pump",
    rooms : [Room.InstallationsRoom]
  },
  {
    url: 'models/rooms/installationsRoom/twowaycounter.glb',
    name: "InstallationsRoom_TwowayCounter",
    rooms : [Room.InstallationsRoom]
  },

  //UndergroundGarage
  {
    url: 'models/rooms/undergroundGarage/underground_garage.glb',
    name: "UndergroundGarage",
    rooms : [Room.UndergroundGarage]
  },
  {
    url: 'models/rooms/undergroundGarage/car.glb',
    name: "UG_Car",
    rooms : [Room.UndergroundGarage]
  },
  {
    url: 'models/rooms/undergroundGarage/wallbox.glb',
    name: "UG_Wallbox",
    rooms : [Room.UndergroundGarage]
  },


  //Kitchen
  {
    url: "models/rooms/kitchen/kitchen_compressed.glb",
    name: "Kitchen",
    rooms : [Room.Kitchen]
  },
   {
    url: "models/rooms/kitchen/fridge.glb",
    name: "Fridge",
    rooms : [Room.Kitchen]
  },
  {
    url: "models/rooms/kitchen/stove.glb",
    name: "Stove",
    rooms : [Room.Kitchen]
  }, 
  {
    url: "models/rooms/kitchen/boiler.glb",
    name: "Boiler",
    rooms : [Room.Kitchen]
  }, 

  // BAsement
  {
    url: "models/rooms/basement/basement.glb",
    name: "Basement",
    rooms : [Room.Basement]
  }, 
  {
    url: "models/rooms/basement/inverter.glb",
    name: "Inverter",
    rooms : [Room.Basement]
  }, 
  {
    url: "models/rooms/basement/meter.glb",
    name: "Meter",
    rooms : [Room.Basement]
  },
   {
    url: "models/rooms/basement/battery.glb",
    name: "Battery",
    rooms : [Room.Basement]
  }, 
  {
    url: "models/rooms/basement/pump.glb",
    name: "Pump",
    rooms : [Room.Basement]
  },

  // House
  {
    url: "models/rooms/house/house_compressed.glb",
    name: "House",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/car.glb",
    name: "Car",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/tree.glb",
    name: "Tree",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/sattel/satteldach.glb",
    name: "Satteldach",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/sattel/pvsattelgross.glb",
    name: "PVSattelGross",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/sattel/pvsattelklein.glb",
    name: "PVSattelKlein",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/sattel/pvsattelziegel_compressed.glb",
    name: "PVSattelZiegel",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/zelt/zeltdach.glb",
    name: "Zeltdach",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/zelt/pvzeltgross.glb",
    name: "PVZeltGRoss",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/zelt/pvzeltklein.glb",
    name: "PVZeltKlein",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/zelt/pvzeltziegel_compressed.glb",
    name: "PVZeltZiegel",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/flach/flachdach.glb",
    name: "Flachdach",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/flach/pvflachgross.glb",
    name: "PVFLachGross",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/flach/pvflachklein_compressed.glb",
    name: "PVFlachKlein",
    rooms : [Room.House]
  },
  {
    url: "models/rooms/house/anim_pv_compressed.glb",
    name: "Anim_PV",
    rooms : [Room.House]
  }, 
  {
    url: "models/rooms/house/anim_solarziegel_compressed.glb",
    name: "Anim_Solarziegel",
    rooms : [Room.House]
  },
  {
    url: "models/rooms/house/anim_balkonmodule_compressed.glb",
    name: "Anim_Balkonmodule_House",
    rooms : [Room.House]
  },
  {
    url: "models/rooms/house/anim_dachmodule.glb",
    name: "Anim_Dachmodule",
    rooms : [Room.House]
  },
  {
    url: "models/rooms/house/stromkabel.glb",
    name: "Stromkabel",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/sattel/wetterhahn_sattel.glb",
    name: "Wetterhahn_Sattel",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/zelt/wetterhahn_zelt.glb",
    name: "Wetterhahn_Zelt",
    rooms : [Room.House]
  },
  {
    url: "models/rooms/house/flach/wetterhahn_flach.glb",
    name: "Wetterhahn_Flach",
    rooms : [Room.House]
  }, {
    url: "models/rooms/house/pumpinverter.glb",
    name: "Pumpinverter",
    rooms : [Room.House]
  }, 
  // Balkon
  {
    url: "models/rooms/balcony/balcony_compressed.glb",
    name: "Balcony",
    rooms : [Room.Balcony]
  },
  {
    url: "models/rooms/balcony/balkonmodul_montage.glb",
    name: "BalconyMontageAnimation",
    rooms : [Room.Balcony]
  },{
    url: "models/rooms/balcony/balkonmodul_static.glb",
    name: "BalconyMontageStatic",
    rooms : [Room.Balcony]
  },



  //Solarzelle
  {
    url: "models/rooms/solarzelle/solarzelle.glb",
    name: "Solarzelle",
    rooms : [Room.Solarzelle]
  },

  // ApartmentBuilding
  {
    url: 'models/rooms/apartment_building/apartment_building.glb',
    name: "ApartmentBuilding",
    rooms : [Room.ApartmentBuilding]
  },{
    url: 'models/rooms/apartment_building/pvbasic_optimized.glb',
    name: "ApartmentBuildingPVBasic",
    rooms : [Room.ApartmentBuilding]
  },{
    url: 'models/rooms/apartment_building/pvbasic_montage.glb',
    name: "ApartmentBuildingPVBasicMontage",
    rooms : [Room.ApartmentBuilding]
  },{
    url: 'models/rooms/apartment_building/pvfront.glb',
    name: "ApartmentBuildingPVFront",
    rooms : [Room.ApartmentBuilding]
  },{
    url: 'models/rooms/apartment_building/pvfront_montage.glb',
    name: "ApartmentBuildingPVFrontMontage",
    rooms : [Room.ApartmentBuilding]
  },
  {
    url: 'models/rooms/apartment_building/pvgruen_montage.glb',
    name: "ApartmentBuildingPVGruen_Montage",
    rooms : [Room.ApartmentBuilding]
  },
  {
    url: 'models/rooms/apartment_building/pvgruen.glb',
    name: "ApartmentBuildingPVGruen",
    rooms : [Room.ApartmentBuilding]
  },{
    url: 'models/rooms/apartment_building/pumpApartment.glb',
    name: "ApartmentBuildingPump",
    rooms : [Room.ApartmentBuilding]
  },
  {
    url: 'models/rooms/apartment_building/apartment_building_stromkabel.glb',
    name: "ApartmentBuildingKabel",
    rooms : [Room.ApartmentBuilding]
  },

  // Hallway
  {
    url: 'models/rooms/hallway/hallway.glb',
    name: "Hallway_Hallway",
    rooms : [Room.Hallway]
  },{
    url: 'models/rooms/hallway/elevator.glb',
    name: "HW_Elevator",
    rooms : [Room.Hallway]
  },{
    url: 'models/rooms/hallway/light.glb',
    name: "HW_Light",
    rooms : [Room.Hallway]
  },{
    url: 'models/rooms/hallway/doorbell.glb',
    name: "HW_Bell",
    rooms : [Room.Hallway]
  },

  //Living Room
  {
    url: 'models/rooms/livingroom/livingroom.glb',
    name: "ApartmentBuildingKabel",
    rooms : [Room.Livingroom]
  },{
    url: 'models/rooms/livingroom/light.glb',
    name: "LR_Light",
    rooms : [Room.Livingroom]
  },{
    url: 'models/rooms/livingroom/tv.glb',
    name: "LR_TV",
    rooms : [Room.Livingroom]
  },{
    url: 'models/rooms/livingroom/vacuum.glb',
    name: "LR_Vacuum",
    rooms : [Room.Livingroom]
  },

];


LoadStack.map((entry)=>{
  entry.url = mainconfig.PAGE_BASE_URL + "/" + entry.url;
});

export default LoadStack;