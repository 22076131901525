import {Car, OperatorConcept, Room} from "@/Enum/Enums";
import {CanvasTexture, MeshBasicMaterial, DoubleSide,NearestFilter,AdditiveBlending} from "three";

class ParticipationController {
  constructor(store) {
    this.store = store;

    const {c, canvas} = this.CreateCanvas();
    this.c = c;
    this.canvas = canvas;
    this.texture = new CanvasTexture(canvas);
    this.texture.flipY = false;
    this.texture.magFilter = NearestFilter;
    //this.texture.generateMipmaps = false;

    this.DrawCanvas();

    this.store.watch(() => store.state.area.participationRate, () => this.DrawCanvas())
    this.store.watch(() => store.state.area.operatorConcept, () => this.DrawCanvas())
    this.store.watch(() => store.state.area.car, () => this.DrawCanvas())

  }


  CreateCanvas() {
    const canvas = document.createElement("canvas");
    canvas.width = 90;
    canvas.height = 300;
    canvas.style.width = 90 + "px";
    canvas.style.height = 300 + "px";


    const c = canvas.getContext("2d");


    return {canvas: canvas, c: c}
  }

  DrawCanvas() {

    this.c.clearRect(0, 0, 100, 300);

    const colors = {
      mieter : "#f38c2f",
      betrieb: "#F9C697",
      car : "#31C5F4"
    }
    
    //Fine Adjustment um auf die Höhe der Stockwerke zu snappen
    let participationRateFactor = 0;
    switch(this.store.state.area.participationRate){
      case .5:
        participationRateFactor = .47
        break;
        case .75:
          participationRateFactor = .735
          break;
          case 1:
            participationRateFactor = 1;
            break;
          }
          
          // 
          const max = 260 * participationRateFactor;
          
    if (this.store.state.area.operatorConcept == OperatorConcept.OnlyOperatorCurrent) {
            
      //Mieterstrom
      this.c.fillStyle = colors.mieter;
      this.c.fillRect(0, 0, 30, 0);
      this.c.fillRect(60, 0, 30, 0);
      
      // this.c.fillStyle = colors.betrieb;
      // // Nur Betriebsstrom
      // this.c.fillRect(30, 0, 30, 300);
      
    } else {
      
      //Mieterstrom
      this.c.fillStyle = colors.mieter;
      this.c.fillRect(0, 250 - max , 30, max);
      this.c.fillRect(60, 250 - max, 30, max);

      // Nur Betriebsstrom
      // this.c.fillStyle = colors.betrieb;
      // this.c.fillRect(30, 0, 30, 300);
    }

  
    this.texture.needsUpdate = true;
  }


  AddMaterialObjects(stack) {

    if (Object.prototype.hasOwnProperty.call(stack, Room.ApartmentBuilding)) {
      stack[Room.ApartmentBuilding].scene.traverse(child => {
        if (child.name == "participationQuote") {

          child.material = new MeshBasicMaterial({
            map: this.texture,
            transparent: true,
            opacity: .8,
            toneMapped: false,
            //blending : AdditiveBlending,
            side: DoubleSide
          });
          child.material.toneMapped = false;

          child.position.x += .05;
        }
      })
    }
  }

}
export default ParticipationController;
