const grassMapSpring = require('../../models/textures/grass_spring.jpg');
const grassMapSummer = require('../../models/textures/grass_summer.jpg');
const grassMapAutumn = require('../../models/textures/grass_autumn.jpg');
const grassMapWinter = require('../../models/textures/grass_winter.jpg');

import { RepeatWrapping, MeshStandardMaterial } from 'three';

class GrassController {

    xr: any;
    store: any;
    grassObjs: any;
    grassTextures: any;
    grassMaterial: any;

    constructor(props: any) {
        this.xr = props.xr;
        this.store = props.store;
        this.grassTextures = {
            "Spring": null,
            "Summer": null,
            "Autumn": null,
            "Winter": null,
        };
        this.grassMaterial = new MeshStandardMaterial()
        this.grassObjs = [];
        this.loadGrassTextures(grassMapSpring, "Spring");
        this.loadGrassTextures(grassMapSummer, "Summer");
        this.loadGrassTextures(grassMapAutumn, "Autumn");
        this.loadGrassTextures(grassMapWinter, "Winter");



        this.store.watch(state => state.area.season, (newValue, oldValue) => {
            this.ChangeGrassMaterial(newValue);
        });
    }

    AddGrassObj(obj) {
        this.grassObjs.push(obj);
        obj.material = this.grassMaterial;
    }

    ChangeGrassMaterial(newVal) {
        this.grassMaterial.map = this.grassTextures[newVal];
        
    }

    loadGrassTextures = (texture, name) => {
        return this.xr.CustomTextureLoader.load(texture).then((texture) => {
            texture.wrapS = RepeatWrapping;
            texture.wrapT = RepeatWrapping;
            texture.repeat.set( 3,3 );
            if (name == 'Summer') {
                this.grassMaterial.map = texture
            }
            this.grassTextures[name] = texture;
        });
    }
}

export default GrassController