import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class Tree implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups =  ["shadow"];
    Xr: any;
    Store: any;


    constructor(xr: any, store:any){
        this.Xr = xr;
        this.Store = store;
        this.Init();
        this.Models = [];
    }

    Init(){
      this.Store.watch(state => state.area.shadow,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{

        this.Models.forEach(model=>{
            model.visible = model.userData.ConfigValue == newVal;
        })
    }

    AddModels(model: Object3D): void {
        model.visible = model.userData.ConfigValue == this.Store.state.area.shadow;
        this.Models.push(model);
    }

    GetModels(): Object3D[] {
        return this.Models;
    }
}

export default Tree;