import {Texture,Color} from 'three';
//import solardaten from '../data/solardaten.json';


class AkkuController{
  constructor(props){
    this.store = props.store;
    this.xr = props.xr;

    this.sunPosition = this.store.state.area.sunPosition;
    this.currentData = null;//this.store.state.area.current.solarDatenSet[this.store.state.area.houseType + "_" + this.store.state.area.season + "_Batterie_" + (this.store.state.area.battery == 'Battery')];
    this.currentIndex = Math.floor(this.sunPosition * 6) < 6 ? Math.floor(this.sunPosition * 6) : 5;
    this.selectedData = null; //this.currentData[this.currentIndex];
    


    
    /*this.store.watch(state => state.world.sceneReady, (newValue,oldValue)=>{
      this.akkuTexture = new Texture(this.CreateCanvas("akku"));
      this.akkuTexture.needsUpdate = true;
    
      this.store.state.world.Library.Keller.scene.traverse(m => {
        if(m.name == "Speicherakku"){
          m.children.map(mesh => {            
            this.akkuMaterial = mesh.material;
            mesh.material.emissive = new Color(0xF38D2F);
            mesh.material.emissiveMap = this.akkuTexture;
          });
        }
      });

      this.AddListener();


      
    });*/
  }

  AddListener = () =>{
    this.xr.Events.addEventListener("OnAnimationLoop" , this.Update);
    this.store.watch(state => state.area.sunPosition, (newValue, oldValue) => {
      this.sunPosition = newValue;
      this.currentIndex = Math.floor(this.sunPosition * 6) < 6 ? Math.floor(this.sunPosition * 6) : 5;
      this.selectedData = this.currentData[this.currentIndex];
    });
    this.store.watch(state => state.area.current.solarDaten, (newValue, oldValue) => {
      this.currentData = newValue;
      this.selectedData = this.currentData[this.currentIndex];
    });

  }

  CreateCanvas = (name) => {
    var canvas = document.createElement("canvas");
    canvas.id = name;
    canvas.width = 256;
    canvas.height = 256;
    canvas.style.width = 256 + "px";
    canvas.style.height = 256 + "px";
    canvas.style.position = "absolute";
    canvas.style.display = "none";
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.zIndex = 99;
    document.body.appendChild(canvas);

    this.c = canvas.getContext("2d");
    this.c.fillStyle = "#F38D2F";
    this.c.fillRect(0,0,256,200);


    return canvas;
  }

  Update = (t) =>{
    this.c.clearRect(0,0,256,256);
    this.c.fillStyle = "#F38D2F";
    this.c.fillRect(0, 256 - (256 * this.selectedData.Ladezustand),256, 256);

    this.c.save();
    //this.c.translate(0,128);
    this.c.scale(1, .7);

    this.c.fillStyle = "#ffffff";
    this.c.font = "bold 50px Inter ";
    this.c.textAlign = "center";
    this.c.textBaseline = "middle";
    this.c.fillText((this.selectedData.Ladezustand * 100).toFixed(0) + "%" , 128, 128); //(this.selectedData.Ladezustand * 100) + "%"
    
    
    this.c.restore();
    //this.c.setTransform(1, 0, 0, 1, 0, 0);

    this.akkuMaterial.emissiveMap.needsUpdate=true;
  }

}

export default AkkuController;