import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class ViewMode{

    Models: Object3D[] = [];
    Xr: any;
    Store: any;


    constructor(xr: any, store:any){
        this.Xr = xr;
        this.Store = store;
        this.Init();
        this.Models = [];
    }

    Init(){
        this.Store.watch(state => state.viewMode,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{
        this.Models.forEach(model=>{
            model.visible = model.userData.ViewMode == newVal;
        })
    }

    AddModels(model: Object3D): void {
        model.visible = model.userData.viewMode == this.Store.state.viewMode;
        this.Models.push(model);
        
    }

    GetModels(): Object3D[] {
        return this.Models;
    }
}

export default ViewMode;