import {ShaderMaterial, UniformsUtils, BackSide, DoubleSide,Color,MeshStandardMaterial} from 'three';

class OutlineMaterial {
  constructor(color = 0xff0000){

    this.outline_material = new MeshStandardMaterial({
      color: color,
      side: BackSide,
      name: "OutlineMaterial"
    });

    this.outline_material.onBeforeCompile = shader => {
      // shader.uniforms.time = { value: 1. };
      // shader.uniforms.own_opacity = { value: 1. };
      // shader.uniforms.color = { value: new Color(0xF9C697) }
      // shader.uniforms.linewidth = { value: .005 }
// 
     
      shader.vertexShader = shader.vertexShader.replace(
        '#include <fog_vertex>',
        '#include <fog_vertex> \n vec4 pos = modelViewMatrix * vec4( position + normal * .005 , 1.0 );\n gl_Position = projectionMatrix * pos;\n'
      );

      // shader.fragmentShader = 'uniform float time;\n uniform float own_opacity;\n uniform vec3 color;\n varying vec2 vUv;\n' + shader.fragmentShader;
      // shader.fragmentShader = shader.fragmentShader.replace(
      //   '#include <dithering_fragment>',
      //   [
      //     '#include <dithering_fragment>',
      //     'gl_FragColor = vec4( color ,own_opacity);'
      //   ].join('\n'));

      this.outline_material.shader = shader;
    };
    return this.outline_material





    // this.outline_shader = {
    //   uniforms: {
    //       "linewidth":  { 
    //         type: "f", 
    //         value: 0.4
    //       },
    //       "color" : {
    //         type : "vec3",
    //         value : new Color(0xF9C697)
    //       },
    //       "time" : {
    //         type : "f",
    //         value : 1
    //       }
    //   },
    //   vertex_shader: [
    //       "uniform float linewidth;",
    //       "uniform float time;",
    //       "void main() {",
    //       "vec4 pos = modelViewMatrix * vec4( position + normal * linewidth, 1.0 );",
    //       "gl_Position = projectionMatrix * pos;",  
    //     "}"
    //     ].join("\n"),
    //     fragment_shader: [
    //       "uniform vec3 color;",
    //       "void main() {",
    //           "gl_FragColor = vec4( color , 1.0 );",
    //       "}"
    //   ].join("\n")
    // };

    // var outline_material = new ShaderMaterial({
    //     uniforms: UniformsUtils.clone(this.outline_shader.uniforms),
    //     vertexShader: this.outline_shader.vertex_shader,
    //     fragmentShader: this.outline_shader.fragment_shader
    // });

    // outline_material.side = BackSide;

    // return outline_material;


  }
}



export default OutlineMaterial;