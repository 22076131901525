import webXRScene from '../webXRScene/src/index';

import { Texture, EquirectangularReflectionMapping,SphereGeometry,MeshBasicMaterial,Mesh,Vector3, FrontSide } from 'three';

import MaterialLibrary from './MaterialLibrary';
import SceneLoader from './SceneLoader';
import TWEEN from '@tweenjs/tween.js';
import Screen from './Screen';

import Points from './Points';
import SceneController from '../Scene/SceneController';
import AnimationController from '../Scene/AnimationController';

//ROOMS
import RoomTinyCity from './Rooms/RoomTinyCity'
import RoomKitchen from './Rooms/RoomKitchen'
import RoomHouse from './Rooms/RoomHouse'
import RoomApartmentBuilding from './Rooms/RoomApartmentBuilding'
import RoomBasement from './Rooms/RoomBasement';
import RoomSolarzelle from './Rooms/RoomSolarzelle';
import RoomUndergroundGarage from './Rooms/RoomUndergroundGarage'
import RoomInstallations from './Rooms/RoomInstallations'
import RoomBalcony from './Rooms/RoomBalcony';
import RoomHallway from './Rooms/RoomHallway';
import RoomLivingroom from './Rooms/RoomLivingRoom';

//INTERACTIVEOBJECTS
import InteractiveObjectController from './InteractiveObjects/InteractiveObjectController';

//CONFIGOBJECTS
import ParticipationController from './ParticipationRate/ParticipationController';

import ElevatorController from './Elevator/ElevatorController';

import ConfigObjectController from './ConfigObjects/ConfigObjectController';

import SeasonController from './Seasons/SeasonController';

import MaterialController from './Materials/MaterialController';

import DashboardController from './Dashboard/DashboardController';

import SprungMarkenController from './Sprungmarken/SprungmarkenController';
import FunfactController from './Sprungmarken/FunfactController';

import AudioController from '../Components/Audio/AudioController';

import TinyCityController from './TinyCity/TinyCityController';

import VRProgress from './ScreenComponents/VRProgress';

import debug_light_mixin from '../Debug/debug_light_mixin';
import debug_post_mixin from '../Debug/debug_post_mixin';
import mainConfig from '../../main.config';
import ThreeMeshUI from 'three-mesh-ui';
import Sky from './Sky';
import NeutralSky from './NeutralSky';
import axios from 'axios';


class MainScene {

  screen = null;
  AudioController = null;

  constructor(props) {
    this.router = props.router;
    this.store = props.store;


    window._router = this.router;

    this.domElement = document.getElementById(props.domElement);
    
    this.xr = new webXRScene(props.domElement);
    this.xr.Controls.SetPosition(-10, 10, 10);
        
    this.xr.Events.registerEvent("OnPOIChange");

    this.sceneController = new SceneController(this);

    this.vrControllerButtonsRight = {
      A : false
    }

    this.AnimationController = new AnimationController({
      store: this.store,
      xr: this.xr
    });

    //debug
    window._xr = this.xr;

    //Init rooms
    this.rooms = {
      TinyCity: new RoomTinyCity(this.xr, this.store, this.router),
      Kitchen: new RoomKitchen(this.xr),
      House: new RoomHouse(this.xr, this.store),
      ApartmentBuilding: new RoomApartmentBuilding(this.xr, this.store),
      Basement: new RoomBasement(this.xr),
      Solarzelle: new RoomSolarzelle(this.xr, this.store),
      InstallationsRoom: new RoomInstallations(this.xr,this.store),
      UndergroundGarage: new RoomUndergroundGarage(this.xr,this.store),
      Balcony: new RoomBalcony(this.xr),
      Hallway: new RoomHallway(this.xr),
      Livingroom: new RoomLivingroom(this.xr),
    }

    this.interactiveObjectController = new InteractiveObjectController({
      xr: this.xr,
      store: this.store,
      router: this.router
    });

    this.configObjectController = new ConfigObjectController({
      xr: this.xr,
      store: this.store
    });

    this.seasonController = new SeasonController({
      xr: this.xr,
      store: this.store
    });

    this.participationController = new ParticipationController(this.store);
    
    this.elevatorController = new ElevatorController(this.store);

    this.materialController = new MaterialController({
      xr: this.xr,
      store: this.store
    });

    this.dashboardController = new DashboardController({
      xr: this.xr,
      store: this.store,
      router: this.router
    });


    this.sprungMarkenController = new SprungMarkenController({
      xr: this.xr,
      store: this.store,
      router: this.router
    });
    
    this.funfactController = new FunfactController({
      xr: this.xr,
      store: this.store,
      router: this.router
    });


    this.tinyCityController = new TinyCityController({
      xr : this.xr,
      store : this.store,
      router : this.router
    })
    // this.interactiveObjects = {
    //   Wasserkocher : null,
    //   Wallbox : new Wallbox(),
    //   Boiler : new Boiler()
    // }


    this.sky = new Sky({xr: this.xr, store : this.store});
    this.neutralSky = new NeutralSky({xr: this.xr, store : this.store});

    // this.xr.Events.addEventListener("OnLoad",()=>{
    //   this.sceneController.SetPosition(this.store.state.content.currentPOI);
    // });

    if (mainConfig.vr_development) {
      //this.screen = new Screen(this);
      var geometry = new SphereGeometry(1, 32, 16);
      var material = new MeshBasicMaterial({
        color:0x663399
      });
      this.placeholderMenu = new Mesh(geometry, material);
      this.placeholderMenu.position.set(0, .5, 21.2)
      this.placeholderMenu.name = "PLACEHOLDER"
      this.xr.Controls.cameraHelper.attach(this.placeholderMenu);
    }

    this.xr.Events.addEventListener("OnChangeXRView", (mode) => {
      if (mode.xrMode == "VR") {
        this.xr.Renderer.postprocessing.enabled = false;
        if (this.screen === null) {
          this.screen = new Screen(this);
        } else {
          this.screen.SetVisible(true);
        }
        //Set static VR Position
        this.xr.Controls.SetPosition(0, .5, 22);
        this.xr.Controls.SetTarget(0, 0, 0);
        

        this.VRProgress = new VRProgress({
          xr: this.xr,
          store: this.store,
          router: this.router
        });

        this.OptimizeForVR();

      } else {

        if (this.screen != null) {
          this.screen.SetVisible(false);
        }

      }
    });



    //Load Audio
    if(this.AudioController == null){
      this.AudioController = new AudioController(this.store);
    }
    //Load Scene
    this.loader = new SceneLoader(this);
    this.loader.setRooms(this.rooms);

    this.xr.Events.addEventListener("OnAnimationLoop", () => TWEEN.update());
    this.xr.Events.addEventListener("OnAnimationLoop", () => ThreeMeshUI.update());
   
    

    //Experimental VR Controls
    this.xr.Events.addEventListener("gamepad", this.HandleVRGamepad);
    this.xr.Events.addEventListener("gamepad2", this.HandleVRGamepad2);

    // this.xr.Renderer.EnablePostProcessing(()=>{
    //   this.xr.Renderer.postprocessing.enabled = true;

    //   // this.xr.Renderer.postprocessing.bloomPass.threshold = .99;
    //   // this.xr.Renderer.postprocessing.bloomPass.strength = .9;
    //   // this.xr.Renderer.postprocessing.bloomPass.radius = .2;
    //   // this.xr.Renderer.postprocessing.bloomPass.exposure = 2.0;

    //   // this.xr.Renderer.postprocessing.bokehPass.uniforms.focus.value = 18;
    //   // this.xr.Renderer.postprocessing.bokehPass.uniforms.aperture.value = .0062;
    //   // this.xr.Renderer.postprocessing.bokehPass.uniforms.maxblur.value =  0.002;
    // });



    //apply default


    this.store.watch(state => state.area.car, this.UpdateShadows);
    this.store.watch(state => state.area.shadow, this.UpdateShadows);
    this.store.watch(state => state.area.houseType, this.UpdateShadows);
    this.store.watch(state => state.area.season, this.UpdateShadows);
    this.store.watch(state => state.area.pump, this.UpdateShadows);
    this.store.watch(state => state.area.battery, this.UpdateShadows);
    this.store.watch(state => state.area.pvSize, this.UpdateShadows);
    this.store.watch(state => state.area.sunPosition, this.UpdateShadows);
    this.store.watch(state => state.content.currentPOI, this.UpdateShadows);
    this.store.watch(state => state.content.currentSubTab,  this.UpdateShadows);
    
    this.store.watch(state => state.viewMode, this.UpdateShadows);
    this.store.watch(state => state.content.currentViewContent, this.UpdateShadows);


    this.UpdateLightSettings = debug_light_mixin.methods.UpdateLightSettings.bind(this);

    this.store.watch(state => state.content.currentViewContent, (val) => {
      this.UpdateLightSettings(val);
      debug_post_mixin.methods.UpdatePostSettings(val);
    });
    
    this.store.watch(state => state.area.season, b => this.UpdateLightSettings(this.store.state.content.currentViewContent));
  }


  OptimizeForVR(){

    Object.keys(this.xr.SceneController.scenes).map(sceneName =>{
      console.log( "OptimizeForVR" , this.xr.SceneController.scenes[sceneName]);

      if(['UI', 'TinyCity'].includes(sceneName)){return;}
      this.xr.SceneController.scenes[sceneName].traverse((child)=>{
        if(child.material != undefined){
          child.material.normalMap = null;
        }

        if(child.type == "SkinnedMesh" || child.type == "Mesh"){
          child.frustumCulled = true;
        }
        
        if(child.type == "Mesh"){
          child.matrixAutoUpdate = false;
        }

        
      })

    })

  }

  UpdateShadows = () => {

    if( this.store.state.xrMode != "VR"){
    this.xr.Renderer.instance.shadowMap.needsUpdate = true;
    }


    if (this.xr.Scene.sky != null && this.store.state.xrMode != "VR") {
      this.xr.Scene.sky.rt = this.xr.Scene.sky.generator.fromEquirectangular(this.xr.Scene.sky.skyTexture);
      if (this.xr.Scene.environment) {
        this.xr.Scene.reflectiveObjects.map((el) => {
          el.material.envMap = this.xr.Scene.sky.rt.texture;
          el.material.envMapIntensity = .5;
        })
      }
    }
  }



  // AddInteractiveObjects(stack){

  //   console.log("AddInteractiveObjcets " , stack);

  //   Object.keys(this.interactiveObjects).map(iObj => {

  //     if(stack.hasOwnProperty(iObj)){
  //       if(stack[iObj].scene.userData.CMS_Reference === iObj){
  //         this.interactiveObjects[iObj].AddModels(stack[iObj].scene);
  //       }
  //     }

  //   });
  // }
  HandleVRGamepad = (gamepadInfo) => {

    //Move Player
    // this.xr.Controls.TranslatePosition(gamepadInfo.axes[2], 0 ,gamepadInfo.axes[3]);

    this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].position.x += gamepadInfo.axes[2] * -.1;
    this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].position.z += gamepadInfo.axes[3] * -.1;

    

    




  }
  
  HandleVRGamepad2 = (gamepadInfo) => {


    if(gamepadInfo.buttons[4].pressed && !this.vrControllerButtonsRight.A ){
      this.vrControllerButtonsRight.A = true;
      this.SaveVRCameraPosition();
      console.log("Button A Pressed");
    }else if(!gamepadInfo.buttons[4].pressed){
      
      this.vrControllerButtonsRight.A = false;
    }


    // this.xr.Controls.TranslatePosition(0, gamepadInfo.axes[3] * -.2,0);
    // this.xr.Controls.TranslateRotation(0, gamepadInfo.axes[2] * -.05 ,0);

    //Rotate Scene
    this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].rotation.y += gamepadInfo.axes[2] * -.05;
    //this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].rotation.x = 0;
    //this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].rotation.z = 0;

    this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].position.y += gamepadInfo.axes[3] * -.1;
    // //Scale Scene
    // this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].scale.x += gamepadInfo.axes[3] * -.05;
    // this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].scale.y += gamepadInfo.axes[3] * -.05;
    // this.xr.SceneController.sceneGroups[this.xr.SceneController.activeScene].scale.z += gamepadInfo.axes[3] * -.05;
    

  }


  SaveVRCameraPosition(){
    const subTab = this.store.state.content.currentSubTab;
    if (subTab == null) {
      return;
    }

    const id = this.store.state.content.currentPOI.id;

    var subTabData = this.store.state.content.currentPOI.SubTabs.map((subTab) => {
      var obj = {
        id: subTab.id,
        __component: "sub-tab-component.sub-headline",
      };

      if (subTab.id === this.store.state.content.currentSubTab.id) {
        var pos = this.store.state.world.mainScene.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].position;
        var scale = this.store.state.world.mainScene.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].scale;
        var target = this.store.state.world.mainScene.xr.SceneController.sceneGroups[this.store.state.world.mainScene.xr.Scene.name].rotation;
          
        obj.subtabVR_Position = { x: pos.x, y: pos.y, z: pos.z };
        obj.subtabVR_Scale = { x: scale.x, y: scale.y, z: scale.z };
        obj.subtabVR_Target = { x: target.x, y: target.y, z: target.z };
      }

      return obj;
    });


    console.log(subTabData);

    axios({
      method: "put",
      url: mainConfig.CMS_BASE_URL + "/points/" + id,
      data: {
        SubTabs: subTabData,
      },
    }).then((response) => {
      if (response.status === 200) {
        console.log("Yes! Subtab Position wurde gesetzt");
      }
      console.log(response);
    });
  }

  GetVRButton() {
    return this.xr.Controls.GetVRButton();
  }
}

export default MainScene;