import { Object3D,Vector3 } from "three/build/three.module";
import IConfigObject from "./IConfigObject";



class Roof implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups = ["houseType"];
    Xr: any;
    Store: any;


    constructor(xr: any, store: any) {
        this.Xr = xr;
        this.Store = store;
        this.Init();
        this.Models = [];
    }

    Init() {
        this.Store.watch(state => state.area.houseType, this.HandleConfigChange);
        this.Store.watch(state => state.area.pvSize, this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal) => {

        this.Models.forEach(model => {

            model.visible = false;
            
            var groups = model.userData.ConfigGroup.split(', '); //houseTpe
            var values = model.userData.ConfigValue.split(', '); //Zelt.Sattel

            groups.forEach((group, index) => {
                values = values[index].split('.');
                
                if (values.includes(this.Store.state.area[group])) {
                    model.visible = true;
                }
            })

 
            if (model.userData.RooftileOcclusion != null) {
                this.Occlude(model);
            }
        })
    }


    Occlude = (model) => {

        if(model.neighbour){
            return;
        }

        var groups = model.userData.RooftileOcclusion.split('.');

        if (groups.includes(this.Store.state.area.pvSize)) {
          model.visible = false;
        }
    }


    AddModels(model: any): void {

      console.log("Roof add Model", model.userData.ConfigValue, model , model.name);
       
        model.visible = model.userData.ConfigValue == this.Store.state.area.houseType;

        var target = new Vector3();
        model.getWorldPosition(target);
        target = target.sub(this.Xr.SceneController.sceneGroups[this.Xr.SceneController.activeScene].position)
       
        var modelLeft = model.clone();
            modelLeft.name = "_left_" + model.name;
        modelLeft.position.y = target.y;

        var modelRight = model.clone();
        modelRight.name = "_right_" + model.name;
        modelRight.position.y = target.y;

        modelLeft.position.x -= 13;
        modelRight.position.x += 13;

        if(this.Store.state.xrMode == "VR"){
          if(model.userData.ConfigValue != "Zelt"){
            modelLeft.position.y = 2.8;
            modelRight.position.y = 2.8;
          }else{
            modelLeft.position.y = 5.670;
            modelRight.position.y = 5.670;
          }
        }

        modelLeft.neighbour = true;
        modelRight.neighbour = true;
        model.neighbour = false;

        this.Xr.SceneController.AddToScene("House", modelRight);
        this.Xr.SceneController.AddToScene("House", modelLeft);

        if (model.userData.hasOwnProperty("RooftileOcclusion")) {
          this.Occlude(model);
        }

        this.Models.push(model);
        this.Models.push(modelRight);
        this.Models.push(modelLeft);

    }

    GetModels(): Object3D[] {
        return this.Models;
    }
}

export default Roof;