<template>
  <div class="dashboard-3d-icon">
    <template v-if="name == 'batterie'">
      <div
        :class="{
          small: room == Room.House,
          big: room == Room.ApartmentBuilding,
        }"
      >
        <BatteryIcon />Stromspeicher
      </div>
    </template>
    <template v-if="name == 'verbraucher'"
      ><div class="small"><Kueche />Eigenverbrauch</div></template
    >
    <template v-if="name == 'stromzaehler'"
      ><div :class="{
          small: room == Room.House,
          big: room == Room.ApartmentBuilding,
        }"><StromzaehlerIcon />Stromzähler</div></template
    >
    <template v-if="name == 'pv'"
      ><div :class="{
          small: room == Room.House,
          big: room == Room.ApartmentBuilding,
        }"><Solarmodul />PV</div></template
    >
    <template v-if="name == 'wechselrichter'"
      ><div :class="{
          small: room == Room.House,
          big: room == Room.ApartmentBuilding,
        }"><Batterie />Wechselrichter</div></template
    >
    <template v-if="name == 'stromnetz'"
      ><div :class="{
          small: room == Room.House,
          big: room == Room.ApartmentBuilding,
        }"><Strommast />Stromnetz</div></template
    >

    <template v-if="name == 'Stromnetz'"
      ><div class="big"><Strommast />Stromnetz</div></template
    >
    <template v-if="name == 'Solar'"
      ><div class="big"><Strommast />Solar</div></template
    >

    <template v-if="name == 'mieter'"
      ><div class="big"><Strommast />Wohnungsstrom</div></template
    >

    <template v-if="name == 'betriebsstrom'"
      ><div class="big"><Strommast />Betriebsstrom</div></template
    >
  </div>
</template>

<script>
import BatteryIcon from "../../UI_Sections/Dashboard/Battery";
import StromzaehlerIcon from "../../SVG/Dashboard/Stromzaehler";
import Strommast from "../../SVG/Article/DGSZentrum_IconSet_Netz_Icon";
import Batterie from "../../SVG/Article/DGSZentrum_IconSet_PvGeräte_Icon";
import Solarmodul from "../../SVG/Article/DGSZentrum_IconSet_PvModule_Icon";
import Kueche from "../../SVG/Article/DGSZentrum_IconSet_Alltagsverbrauch_Icon";

import { Room } from "../../Enum/Enums";

export default {
  name: "DashboardIcon",
  props: {
    name: {
      type: String,
      default: "",
    },
    room: {
      type : String,
      default : Room.House
    }
  },
  components: {
    BatteryIcon,
    Kueche,
    StromzaehlerIcon,
    Solarmodul,
    Batterie,
    Strommast,
  },
  data() {
    return {
      Room: Room,
    };
  },
  mounted() {},
};
</script>

<style lang="scss">
.dashboard-3d-icon {
  border-radius: 15px;
  background: #fff;
  padding: 0.5rem 1.5rem 0.5rem 0.25rem;
  border:1px solid transparent;
  line-height: 40px;

  opacity: 0;

  animation : blendIn 2s forwards;
}

@keyframes blendIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.big {
  svg {
    width: 120px;
    height: 120px;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    * {
      fill: $orange;
    }
  }
  color: #333;
  font-weight: 1000;
  font-size: 2.5rem;
  white-space: nowrap;
  
  > div {
    display: inline-block;
  }
}

.small {
  display: flex;
  svg {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    * {
      fill: $orange;
    }
  }
  color: #333;
  font-weight: 700;
  font-size: 1.2rem;
}
</style>
