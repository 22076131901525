import {Room} from "@/Enum/Enums";
import {Tween, Easing} from "@tweenjs/tween.js";
import {Vector3} from 'three';


class ElevatorController {

  constructor(store) {
    this.store = store;
    this.elevatorBone = null;
    this.targetPoints = [];
    this.initialized = false;

    // this.store.watch(()=>store.state.world.mainScene, ()=>{

    // this.store.state.world.mainScene.xr.Events.addEventListener("OnAnimationLoop",this.Animate);
    // })
  }

  AddObjects(stack) {
    //console.log("ElevatorController", stack);
    if (Object.prototype.hasOwnProperty.call(stack, Room.ApartmentBuilding)) {
      stack[Room.ApartmentBuilding].scene.traverse(child => {

        if (Object.prototype.hasOwnProperty.call(child.userData, "ElevatorPosition")) {
          this.targetPoints.push(child);
        }

        if (child.name == "elevatorBone") {
          this.elevatorBone = child;
        }

      });


      this.initialized = true;

      this.MoveElevatorToNextPoint();
    }
  }

  MoveElevatorToNextPoint() {
    const position = this.elevatorBone.position.clone();
    const getRandomNextTarget = this.targetPoints[Math.floor(Math.random() * this.targetPoints.length)];

    const randomWaitingDuration = Math.random();

    const distance = Math.abs(Math.abs(getRandomNextTarget.position.y) - Math.abs(position.y));

    //console.log(randomWaitingDuration, distance);


    const tween = new Tween({y: position.y}).to({
      y: getRandomNextTarget.position.y
    }, 1000 * distance).easing(Easing.Quadratic.Out).onUpdate((v) => {
      this.elevatorBone.position.y = v.y;
    }).onComplete(() => {

      setTimeout(()=>{
        this.MoveElevatorToNextPoint();
      }, randomWaitingDuration);

    }).start();

  }

}

export default ElevatorController;
