import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class GreenRoof implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups =  ["greenRoof"];
    Xr: any;
    Store: any;

    constructor(xr: any, store:any){
      this.Xr = xr;
      this.Store = store;
      this.Init();
      this.Models = [];
    }

    Init(){
      this.Store.watch(state => state.area.greenRoof,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{
      
        this.Models.forEach(model=>{
          model.visible =  model.userData.ConfigValue == newVal;
        });
        
        this.Store.state.world.mainScene.rooms.ApartmentBuilding.SwitchRoof(newVal)
    }

    AddModels(model: Object3D): void {

      this.Models.push(model);
        
      this.HandleConfigChange("GreenRoof", null);

    }

    GetModels(): Object3D[] {
      return this.Models;
    }
}

export default GreenRoof;