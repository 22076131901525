export default {
  Spring : [
    "#5EB94B",
    "#CBDD84",
    "#9FD380",

    "#82C790",
    "#C8DF9C",
    "#ADD136",

    "#F2F6E7",
    "#ECF5DD",
    "#CAE3C8",

  ],
  Summer : [
    "#D5D96C",
    "#B9E703",
    "#94D31B",

    "#5D8C16",
    "#2F7E0C",
    "#156116",

    "#BEE1B0",
    "#7ACB84",
    "#D37B66"
  ],
  Autumn : [
    "#FACD23",
    "#FA8D19",
    "#F3502F",

    "#68915D",
    "#6F8F3C",
    "#576D44",

    "#F7BA79",
    "#DA954E",
    "#C37233"
  ],
  Winter : [
    "#D0E5F2",
    "#BAD1E2",
    "#CAD5D9",

    "#CDDFE8",
    "#B8D5D9",
    "#95BFAC",

    "#FFFFFF",
    "#F3F3EE",
    "#E9E4E5"
  ],    
}