import IInteractiveObject from "./IInteractiveObject";
import OutlineMaterial from '../InteractiveObjects/OutlineMaterial';
import { Object3D, Mesh } from 'three';
import {Pump} from '../../Enum/Enums';

class Pumpinverter implements IInteractiveObject {
  CMSReference: String = "Pumpinverter";
  Models: Object3D[] = [];
  Xr: any;
  store : any;
  OutlineMaterial : any  = new OutlineMaterial();


  constructor(props: any){
    this.Xr = props.xr;
    this.store = props.store;

    this.store.watch(state => state.area.pump , (newValue)=>{
      var outlineModel = this.Models.find(m => m.name == "Copy");
      if(typeof(outlineModel) == "undefined"){return;}
      outlineModel.children[0].visible = newValue == Pump.Pump;      
    });
  }

  AddModels(model: Mesh): void {

    var modelCopy = model.clone();
        modelCopy.name = model.name + "_copy";
       
    model.userData.modelCopy = modelCopy;
    model.add(modelCopy);
    this.Models.push(modelCopy);
    this.Models.push(model);

  }
  GetModels(): Object3D[] {
    throw new Error('Method not implemented.');

  }
  OnEnterScene(): void {
    console.log("%c Enter Scene where Pumpinverter is present", "color: #fff;background:green;");
  }
  OnExitScene(): void {
    console.log("%c Exit Scene where Pumpinverter is present", "color: #fff;background:orange;");
  }

  OnSelect(): void {
    console.log("%c Select Pumpinverter Subtab", "color: #000;background:lightgreen;");
  }
  OnDeselect(): void {
    console.log("%c DeSelect Pumpinverter Subtab", "color: #000;background:tomato;");
  }

  OnHover(arg: any): void {
    throw new Error('Method not implemented.');
  }
  OnRenderUI(arg: any): void {
    throw new Error('Method not implemented.');
  }

}

export default Pumpinverter;