import { Object3D, Vector2, Vector3, Color, Mesh, Material, Group, BufferGeometry, Float32BufferAttribute } from "three/build/three.module";
import Vue from 'vue';

import { MeshLine, MeshLineMaterial } from '../meshline';
import ThreeMeshUI from 'three-mesh-ui';

import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import DashboardIcon from './DashboardIcons.vue';

import batterie from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Batterie.png';
import verbrauch from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Eigenverbrauch.png';
import pv from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-PVAnlage.png';
import stromnetz from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Stromnetz.png';
import stromzaehler from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Stromzaehler.png';
import wechselrichter from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Wechselrichter.png';
import mieter from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Mieter.png';
import betriebsstrom from '../../models/flaggen/DGSZentrum_VR_Dashboard_Flaggen_StromViz-Betriebsstrom.png';

import Utility from "@/utils";
import { OperatorConcept, Room } from "@/Enum/Enums";


class DashboardController {
  store: any;
  xr: any;
  router: any;
  routeName: String = "";
  visible: Boolean = false;
  kabelVisible: Boolean | null = false;
  vr_visible: Boolean | null = false;

  kabelGroup: Object = {};
  //vrKabelGroup: Object = {};

  updateDepthTest: Boolean = true;

  dashMaterial: Material | null = null;
  dashMesh: Mesh | null = null;

  kabel: any = {}
  stromkabel: any = {}
  kabelIcons: any = {}
  vrIcons: any = {}
  vr_kabelIcons: any = {}
  vr_kabelText: any = {}

  icons: any = {}
  colors: any = {}
  initialized: boolean = false;

  dashboardIconClass: any;

  batterieTextElement: HTMLElement | null = null;

  constructor(props: any) {
    this.store = props.store;
    this.xr = props.xr;
    this.router = props.router;

    this.stromkabel = {
      House: {},
      ApartmentBuilding: {}
    }

    this.kabelGroup = {
      House: new Group(),
      ApartmentBuilding: new Group()
    }

    this.kabelIcons = {
      House: {},
      ApartmentBuilding: {}
    }

    this.kabel = {
      House: {},
      ApartmentBuilding: {}
    }

    this.vr_kabelIcons = {
    }

    this.vrIcons = {
      House: new Group(),
      ApartmentBuilding: new Group()
    }

    this.colors = {
      blue: new Color(0x31C5F4),
      orange: new Color(0xF38D2F),
      lightOrange: new Color(0xF9C697),
      neutral: new Color(0xaaaaaa),
    }

    this.dashboardIconClass = Vue.extend(DashboardIcon);

    this.store.watch(state => state.content.currentPOI, (newData, oldData) => {
      this.ToggleDesktopIconKabel();
      this.ToggleVRIconKabel();
      this.ToggleKabel();
    });

    this.store.watch(state => state.content.currentViewContent, (newData, oldData) => {
      this.ToggleDesktopIconKabel();
      this.ToggleVRIconKabel();
      this.ToggleKabel();
    });
    this.router.beforeEach((to, from, next) => {
      this.routeName = to.name;
      this.ToggleDesktopIconKabel();
      this.ToggleVRIconKabel();
      this.ToggleKabel();
      next();
    });

    this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);
  }



  ToggleKabel = (boolean?: Boolean | null) => {
    this.kabelVisible = ((boolean == null) ? (this.routeName == "Dashboard") : boolean);

    if (this.kabelVisible) {
      if (this.router.currentRoute.params.area == 'familyhouse') {
        this.store.state.world.mainScene.xr.SceneController.AddToScene("House", this.kabelGroup['House']);
      } else if (this.router.currentRoute.params.area == 'apartmentbuilding') {
        this.store.state.world.mainScene.xr.SceneController.AddToScene("ApartmentBuilding", this.kabelGroup['ApartmentBuilding']);
      }

    } else {
      this.store.state.world.mainScene.xr.SceneController.RemoveFromScene("House", this.kabelGroup['House']);
      this.store.state.world.mainScene.xr.SceneController.RemoveFromScene("ApartmentBuilding", this.kabelGroup['ApartmentBuilding']);
    }

  }

  ToggleDesktopIconKabel = () => {

    this.visible = this.routeName == "Dashboard";

    let names = Object.keys(this.kabel);
    if (names.length > 0) {
      Object.keys(this.kabelIcons).map((sceneName) => {

        var iconsVisible = false;
        if (this.router.currentRoute.params.area == 'familyhouse') {
          iconsVisible = this.visible && sceneName == 'House'
        } else if (this.router.currentRoute.params.area == 'apartmentbuilding') {
          iconsVisible = this.visible && sceneName == 'ApartmentBuilding'
        }
        Object.values(this.kabelIcons[sceneName]).map((icon: Object3D) => {
          icon.visible = iconsVisible;
        });

      });
    }


  }


  ToggleVRIconKabel = (boolean?: Boolean | null) => {

    this.vr_visible = ((boolean == null) ? (this.routeName == "Dashboard" && this.router.currentRoute.params.xrMode == "VR") : boolean);

    if (this.vr_visible) {
      if (this.router.currentRoute.params.area == 'familyhouse') {
        this.store.state.world.mainScene.xr.SceneController.AddToScene("House", this.vrIcons['House']);
      } else if (this.router.currentRoute.params.area == 'apartmentbuilding') {
        this.store.state.world.mainScene.xr.SceneController.AddToScene("ApartmentBuilding", this.vrIcons['ApartmentBuilding']);
      }
    } else {
      this.store.state.world.mainScene.xr.SceneController.RemoveFromScene("House", this.vrIcons['House']);
      this.store.state.world.mainScene.xr.SceneController.RemoveFromScene("ApartmentBuilding", this.vrIcons['ApartmentBuilding']);
    }

  }

  AddObjects = (kabel: any, sceneName: string) => {
    this.batterieTextElement = document.getElementById("batterie-text") != null ? document.getElementById("batterie-text") : null;
   
    kabel.scene.children.map(child => {

      var verts = Utility.GetVertices(child.geometry, 0.001);
      if(verts == null){return;}

      const vertices: number[] = [];
      verts.map(v => {
        vertices.push(v.x, v.y, v.z);
      })
      var vert32Array = new Float32Array(vertices);
      const line = new MeshLine();

      const material = new MeshLineMaterial({
        color: new Color(0x3F4355),
        lineWidth: .15,
        sizeAttenuation: 1,
        transparent: true,
        depthTest: false,
        resolution: new Vector2(256, 256)
      });

      this.dashMaterial = new MeshLineMaterial({
        color: new Color(0xF38D2F),
        lineWidth: .1,
        sizeAttenuation: 1,
        dashArray: .1,
        dashRatio: .5,
        transparent: true,
        depthTest: false,
        resolution: new Vector2(256, 256)
      });

      line.setPoints(vert32Array);


      const mesh = new Mesh(line, material);

      this.kabel[sceneName][child.name] = new Mesh(line, new MeshLineMaterial({
        color: new Color(0xF38D2F),
        lineWidth: .1,
        sizeAttenuation: 1,
        dashArray: .1,
        dashRatio: .5,
        transparent: true,
        depthTest: false,
        resolution: new Vector2(256, 256)
      }));

      mesh.add(this.kabel[sceneName][child.name]);

      this.stromkabel[sceneName][child.name] = mesh;
    });


    Object.keys(this.stromkabel[sceneName]).map(line => {
      this.kabelGroup[sceneName].add(this.stromkabel[sceneName][line]);
    });


    Object.keys(this.stromkabel[sceneName]).map((kabelType, index) => {

      var pointsArray = this.stromkabel[sceneName][kabelType].geometry.points;
      var points: Vector3[] = [];

      for (var i = 0; i < pointsArray.length; i += 3) {
        points.push(new Vector3(pointsArray[i], pointsArray[i + 1], pointsArray[i + 2]));
      }


      this.kabelIcons[sceneName][kabelType] = this.CreateKabelIcon(kabelType, points[points.length - 1], sceneName);

      this.vr_kabelIcons[kabelType] = this.CreateKabelVRIcon(kabelType, points[points.length - 1]);


      if (index == 1 && (sceneName == 'House' || sceneName == 'ApartmentBuilding')) {
        this.vr_kabelIcons.wechselrichter = this.CreateKabelVRIcon("Wechselrichter", points[0]);
        this.vrIcons[sceneName].add(this.vr_kabelIcons.wechselrichter);

        this.kabelIcons[sceneName].wechselrichter = this.CreateKabelIcon("wechselrichter", points[0], sceneName);
        this.store.state.world.mainScene.xr.CSSSceneController.AddToScene(sceneName, this.kabelIcons[sceneName].wechselrichter);
      } else {
        
        this.vrIcons[sceneName].add(this.vr_kabelIcons[kabelType])
        //this.kabelIcons[sceneName].wechselrichter = this.CreateKabelIcon("wechselrichter", points[0],sceneName);
        this.store.state.world.mainScene.xr.CSSSceneController.AddToScene(sceneName, this.kabelIcons[sceneName][kabelType]);
      }

    });

    this.initialized = true;
  }

  CreateKabelVRIcon(type, position) {
    var kabelTypes = type.split("_");
    var iconType = kabelTypes.pop();

    var width = 2527;
    var height = 632;
    var factor = 1 / 1200

    this.vr_kabelText[type] = new ThreeMeshUI.Block({
      width: width * factor,
      height: height * factor,
      padding: 0.1,
      backgroundOpacity: 1,
      fontSize: .25,
      borderRadius: .1,
      depthTest: false,
      backgroundSize: 'stretch',
      alignContent: "center",
      justifyContent: "center",
    });

    this.vr_kabelText[type].lookAt(this.store.state.world.mainScene.xr.Controls.GetCameraPosition())

    var textureToLoad = '';

    switch (iconType) {
      case 'batterie': textureToLoad = batterie; break;
      case 'pv': textureToLoad = pv; break;
      case 'verbraucher': textureToLoad = verbrauch; break;
      case 'stromnetz': textureToLoad = stromnetz; break;
      case 'Wechselrichter': textureToLoad = wechselrichter; break;
      case 'stromzaehler': textureToLoad = stromzaehler; break;
      case 'Stromnetz': textureToLoad = stromzaehler; break;
      case 'Solar': textureToLoad = stromzaehler; break;
      case 'mieter': textureToLoad = mieter; break;
      case 'betriebsstrom': textureToLoad = betriebsstrom; break;

    }

    this.xr.CustomTextureLoader.load(textureToLoad).then((tex) => {
      this.vr_kabelText[type].set({
        backgroundTexture: tex
      })
    })

    /*this.vr_kabelText[type] = new ThreeMeshUI.Text({
      content: textContent
    });

    textContainer.add(this.vr_kabelText[type]);*/
    this.vr_kabelText[type].position.copy(position);
    this.vr_kabelText[type].position.z += .1;

    return this.vr_kabelText[type];
  }


  CreateKabelIcon(type, position, room) {

    var kabelTypes = type.split("_");
    var iconType = kabelTypes.pop();

    this.icons[iconType] = new this.dashboardIconClass({
      propsData: {
        name: iconType,
        room : room
      }
    });

    this.icons[iconType].$slots.default = ["0%"];
    this.icons[iconType].$mount();
    const object = new CSS3DObject(this.icons[iconType].$el);
    object.position.copy(position);

    // OFFSET
    if(room == Room.House){
      object.position.z += 1.5;
    }else if(room == Room.ApartmentBuilding ){
    
      if(type  == "StromKabel_wechsel_pv"){
        object.position.x += 6;
      }else if(type == "StromKabel_stormzaehler_stromnetz"){
        object.position.x += 6;
        object.position.y = -4;
        object.position.z -= 4;
      }else{
        object.position.z -= 2.5;
        object.position.y -= 3.25;
        object.position.x += 4;
      }

      object.scale.set(1.2,1.2,1.2);
    }
    
    return object;
  }

  GetKabelColor(kabel) {

    var dir = {
      positive: 0.005,
      negative: -0.005,
      neutral: 0
    }

    var result = {
      dir: dir.neutral,
      color: this.colors.neutral,
      dashArray: 0,
    }

    if (this.store.state.area.clockSolarDaten == null) { return result }

    var d = this.store.state.area.clockSolarDaten;
    var out = d.Netzeinspeisung > 0;

    switch (kabel) {
      case "StromKabel_stormzaehler_stromnetz":
        if (d.Netzeinspeisung == 0 && d.Netzbezug < .12) {
          result.color = this.colors.neutral;
          result.dir = dir.neutral;
          result.dashArray = 0;
        } else {
          result.color = out ? this.colors.orange : this.colors.blue;
          result.dir = out ? dir.negative : dir.positive;
          result.dashArray = out ? .1 : .1;
        }

        break;
      case "StromKabel_wechsel_stromzaehler":
        if (d.Netzeinspeisung == 0 && d.Netzbezug < .12) {
          result.color = this.colors.neutral;
          result.dir = dir.neutral;
          result.dashArray = 0;
        } else {
          result.color = out ? this.colors.orange : this.colors.blue;
          result.dir = out ? dir.negative : dir.positive;
          result.dashArray = out ? .1 : .1;
        }
        break;
      case "wechselrichter":
        break;
      case "StromKabel_wechsel_batterie":

        if (d.Laden > 0) {
          result.color = this.colors.orange;
          result.dir = dir.negative;
          result.dashArray = .1;
        } else if (d.Laden < 0) {
          result.color = this.colors.lightOrange;
          result.dir = dir.positive;
          result.dashArray = .1;
        } else if (d.Laden == 0 || this.store.state.area.battery == "NoBattery") {

          // console.log("Battery neutral machen");
          result.color = this.colors.neutral;
          result.dir = 0;
          result.dashArray = 0;
        }

        break;
      case "StromKabel_wechsel_verbraucher":
        var hatEigenverbrauch = d.Eigenverbrauch > 0;
        result.color = hatEigenverbrauch ? this.colors.lightOrange : this.colors.lightOrange;
        result.dir = hatEigenverbrauch ? dir.negative : dir.neutral;
        result.dashArray = hatEigenverbrauch ? .1 : 0;

        break;
      case "StromKabel_stormzaehler_mieter":
        var hatEigenverbrauch = d.Eigenverbrauch > 0;
        result.color = hatEigenverbrauch ? this.colors.lightOrange : this.colors.lightOrange;
        result.color = (this.store.state.area.operatorConcept == OperatorConcept.OperatorCurrentAndCommunity) ? result.color : this.colors.neutral;
        result.dir = hatEigenverbrauch && this.store.state.area.operatorConcept == OperatorConcept.OperatorCurrentAndCommunity ? dir.negative : dir.neutral;
        result.dashArray = hatEigenverbrauch && this.store.state.area.operatorConcept == OperatorConcept.OperatorCurrentAndCommunity ? .1 : 0;

        break;
      case "StromKabel_stormzaehler_betriebsstrom":
        var hatEigenverbrauch = d.Eigenverbrauch > 0;
        result.color = hatEigenverbrauch ? this.colors.lightOrange : this.colors.lightOrange;
        //result.color = (this.store.state.area.operatorConcept != OperatorConcept.OperatorCurrentAndCommunity) ? result.color : this.colors.neutral;
        result.dir = hatEigenverbrauch ? dir.negative : dir.neutral;
        result.dashArray = hatEigenverbrauch ? .1 : 0;

        break;
      case "StromKabel_wechsel_pv":

        var bekommtPVStrom = d.Einstrahlung > 0.02;
        var pvSpeed = d.Einstrahlung > 0.001 ? d.Einstrahlung : 0;
        result.color = bekommtPVStrom ? this.colors.orange : this.colors.neutral;
        result.dashArray = bekommtPVStrom ? .1 : 0;
        result.dir = bekommtPVStrom ? dir.positive : dir.neutral;

        break;
    }

    return result;
  }

  Animate = () => {
    if (this.router.currentRoute.params.area == undefined) {
      return;
    }
    var sceneName = this.router.currentRoute.params.area == 'familyhouse' ? 'House' : this.router.currentRoute.params.area == 'apartmentbuilding' ? 'ApartmentBuilding' : '';
    if (this.kabel[sceneName] == undefined) {
      return;
    }
    if (this.initialized && this.routeName == "Dashboard") {

      var target = this.xr.Controls.GetPosition();
      if (this.router.currentRoute.params.xrMode == "VR") {
        target = this.xr.Camera.instance.position;
      }

      //Animate Colors
      Object.keys(this.kabel[sceneName]).map(kabel => {

        const kabelOptions = this.GetKabelColor(kabel);
        this.kabel[sceneName][kabel].material.uniforms.color.value = kabelOptions.color;
        this.kabel[sceneName][kabel].material.uniforms.dashOffset.value += kabelOptions.dir;
        this.kabel[sceneName][kabel].material.uniforms.dashArray.value = kabelOptions.dashArray;
        this.kabel[sceneName][kabel].material.uniformsNeedUpdate = true;
      });

      if (this.kabelIcons['House'].wechselrichter != undefined) { this.kabelIcons['House'].wechselrichter.lookAt(target) }
      if (this.kabelIcons['ApartmentBuilding'].wechselrichter != undefined) { this.kabelIcons['ApartmentBuilding'].wechselrichter.lookAt(target) }


      //Animate Lookat - desktop
      if (this.router.currentRoute.params.xrMode == "desktop") {
        Object.keys(this.kabel[sceneName]).map(kabel => {
          if (this.kabelIcons[sceneName][kabel] != undefined) {

            if (sceneName == 'House') {
              this.kabelIcons[sceneName][kabel].rotation.set(this.kabelIcons['House'].wechselrichter.rotation.x, this.kabelIcons['House'].wechselrichter.rotation.y, this.kabelIcons['House'].wechselrichter.rotation.z);
            } else {

              this.kabelIcons[sceneName][kabel].lookAt(target)
            }
          }

        })
      }

      //if(this.router.currentRoute.params.xrMode == "VR"){
      //VRIcons      

      //if(this.updateDepthTest){

      Object.keys(this.vr_kabelIcons).map(vrKabelIcon => {
        this.vr_kabelIcons[vrKabelIcon].lookAt(this.store.state.world.mainScene.xr.Controls.GetCameraPosition())
        this.vr_kabelIcons[vrKabelIcon].traverse(child => {
          if (child.hasOwnProperty("backgroundMaterial")) {
            child.backgroundMaterial.depthTest = false;
          }
          if (child.hasOwnProperty("material")) {
            child.material.depthTest = false;
          }
        });
      });

      this.updateDepthTest = false;
    }
  }
}

export default DashboardController;