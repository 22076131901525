import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";
import { Mesh, Vector3 } from 'three';

import OutlineMaterial from '../InteractiveObjects/OutlineMaterial';
import ConfigObjectController from "./ConfigObjectController";

class Car implements IConfigObject {

  Models: Object3D[] = [];
  ConfigGroups = ["car"];
  Xr: any;
  Store: any;
  wire: any = null;

  constructor(xr: any, store: any) {
    this.Xr = xr;
    this.Store = store;
    this.Init();
    this.Models = [];

    this.Xr.Events.addEventListener("OnAnimationLoop", this.Animate);


  }

  Init() {
    this.Store.watch(state => state.area.car, this.HandleConfigChange);
  }

  HandleConfigChange = (newVal, oldVal) => {

    this.Models.forEach(model => {
      model.material.transparent = model.userData.ConfigValue != newVal;
      model.material.opacity = model.userData.ConfigValue == newVal ? 1 : 0.2;
    });
  }

  AddModels(model: Mesh): void {
    this.Models.push(model);
    this.HandleConfigChange("Car", null);
    
  }

  GetModels(): Object3D[] {
    return this.Models;
  }

  Animate = (t) => {
    //this.OutlineMaterial.uniforms.time.value = t.getElapsedTime();
  }
}

export default Car;