import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class Pump implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups =  ["pump"];
    Xr: any;
    Store: any;


    constructor(xr: any, store:any){
        this.Xr = xr;
        this.Store = store;
        this.Init();
        this.Models = [];
    }

    Init(){
      this.Store.watch(state => state.area.pump,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{
      
      this.Models.forEach(model=>{
        var enabled = newVal == "Pump";

        model.castShadow = enabled;
        model.material.opacity = enabled ? 1 : .4;
        model.material.transparent = !enabled;       
      })
    }

    AddModels(model: Object3D): void {
      var visible = model.userData.ConfigValue == this.Store.state.area.pump;

      model.castShadow = visible;
      model.material.opacity = visible ? 1 : .6;
      model.material.transparent = !visible;


      this.Models.push(model);
    }

    GetModels(): Object3D[] {
        return this.Models;
    }
}

export default Pump;