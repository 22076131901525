import {Group, Object3D} from 'three';
import IInteractiveObject from "./IInteractiveObject";
import OutlineMaterial from '../InteractiveObjects/OutlineMaterial';

class HW_Bell implements IInteractiveObject {
  CMSReference: String = "HW_Bell";
  Models: Object3D[] = [];
  Xr: any;
  store: any;
  OutlineMaterial : any  = new OutlineMaterial();


  constructor(props: any){
    this.Xr = props.xr;
    this.store = props.store;
  }

  AddModels(model: Group): void {
    
    var modelCopy = model.clone();
        modelCopy.name = model.name + "_copy";
       
    model.userData.modelCopy = modelCopy;
    model.add(modelCopy);
    this.Models.push(modelCopy);
    this.Models.push(model);

  }
  GetModels(): Object3D[] {
    throw new Error('Method not implemented.');

  }
  OnEnterScene(): void {
    //console.log("%c Enter Scene where Battery is present", "color: #fff;background:green;");
  }
  OnExitScene(): void {
    //console.log("%c Exit Scene where Battery is present", "color: #fff;background:orange;");
  }

  OnSelect(): void {
     //console.log("%c Select Battery Subtab", "color: #000;background:lightgreen;");
    }
  OnDeselect(): void {
   // console.log("%c DeSelect Battery Subtab", "color: #000;background:tomato;");
  }

  OnHover(arg: any): void {
    throw new Error('Method not implemented.');
  }
  OnRenderUI(arg: any): void {
    throw new Error('Method not implemented.');
  }

}

export default HW_Bell;