import {Group} from 'three'
import IConfigObject from "./IConfigObject";
import Tree from "./Tree";
import Pump from "./Pump";
import Pumpinverter from "./Pumpinverter";
import Car from "./Car";
import PV from "./PV";
import Roof from "./Roof";
import Battery from "./Battery";
import ViewMode from './ViewMode';
import Fascade from './Fascade';
import GreenRoof from './GreenRoof';

class ConfigObjectController {
  configObjects: {
    [Name: string]: IConfigObject
  }
  viewObjects :any

  //quick fix ≠ Store<State> => muss den store als eigenen type beschrieben haben 
  //=> siehe https://next.vuex.vuejs.org/guide/typescript-support.html
  store: any

  constructor(props: any) {
    this.store = props.store;

    this.configObjects = {
      "Tree": new Tree(props.xr, props.store),
      "Car": new Car(props.xr, props.store),
      "PV": new PV(props.xr, props.store),
      "Roof": new Roof(props.xr, props.store),
      "Battery": new Battery(props.xr, props.store),
      "Pump": new Pump(props.xr, props.store),
      "Pumpinverter": new Pumpinverter(props.xr, props.store),
      "Fascade": new Fascade(props.xr, props.store),
      "GreenRoof": new GreenRoof(props.xr, props.store),

      
      
    }

    this.viewObjects = new ViewMode(props.xr,props.store)
  }

  AddConfigObjects = (stack: Group) => {
   
    Object.values(stack).map((stackObj:any) => {
      
      stackObj.scene.traverse((child) => {

        if (child.userData?.ConfigGroup != null) {
          
          //console.log("Config Group Param from Model: ",child.userData.ConfigGroup);
          var groups = child.userData.ConfigGroup.split(", ")
          groups = groups.sort().join();
        
          Object.values(this.configObjects).forEach(configObj => {

            var values = configObj.ConfigGroups.sort().join();
            //console.log(values == groups)
            //console.log("check config obj ",configObj,values)

            if(values == groups){
              //console.log("ADD ",child.name)
              configObj.AddModels(child);
            }

          })

        }

        if(child.userData?.ViewMode != null){

          //console.log("VIEWMODE OBJECT ",child)
          this.viewObjects.AddModels(child);

        }
      });
    });
  }
}

export default ConfigObjectController