import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class PV implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups =  ["pvSize","houseType"];
    Xr: any;
    Store: any;


    constructor(xr: any, store:any){
        this.Xr = xr;
        this.Store = store;
        this.Init();
        this.Models = [];
    }

    Init(){
        this.Store.watch(state => state.area.pvSize,this.HandleConfigChange);
        this.Store.watch(state => state.area.houseType,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{
        this.Models.forEach(model=>{
            model.visible = model.userData.ConfigValue.includes(this.Store.state.area.houseType) && model.userData.ConfigValue.includes(this.Store.state.area.pvSize)
        })
    }

    AddModels(model: Object3D): void {    
        model.visible = model.userData.ConfigValue.includes(this.Store.state.area.houseType) && model.userData.ConfigValue.includes(this.Store.state.area.pvSize)
        this.Models.push(model);
        //console.log("ADD Model to PVConfigObject ",this.Models)
    }

    GetModels(): Object3D[] {
        return this.Models;
    }
}

export default PV;