import {LinearEncoding} from 'three';

import LeavesMaterialController from './LeavesMaterialController';
import GrassController from './GrassController';
import TreeController from './TreeController';

class MaterialController {
  xr: any;
  store: any;
  leavesMat: LeavesMaterialController;
  grassController: GrassController;
  treeController: TreeController;


  constructor(props: any) {
    this.xr = props.xr;
    this.store = props.store;

    this.leavesMat = new LeavesMaterialController({
      store: this.store
    });

    this.grassController = new GrassController({ store: this.store, xr: this.xr });
    this.treeController = new TreeController({ store: this.store, xr: this.xr });
  }

  AddMaterialObjects = (stack) => {
    Object.keys(stack).map((libItem)=>{
      stack[libItem].scene.traverse(el =>{
        if(el.type != "Mesh" || el.type != "SkinnedMesh"){return}


          el.castShadow = true;
          el.receiveShadow = true;
        
          if(el.material){
            if(el.material.map != null){
              el.material.map.encoding = LinearEncoding;
            }
          }

      })
    });

    if(Object.prototype.hasOwnProperty.call(stack, "House")){
      this.SearchForGrass(stack.House.scene);
    }

    if(Object.prototype.hasOwnProperty.call(stack, "ApartmentBuilding")){
      this.SearchForGrass(stack.ApartmentBuilding.scene);

    }
    
    if(Object.prototype.hasOwnProperty.call(stack, "Tree")){
      stack.Tree.scene.traverse(child => {
        if (typeof (child.material) != "undefined") {
          this.SearchForLeafes(child);
          this.SearchForTress(child);
        }
      });
    }

  }

  SearchForGrass(scene:any){
    scene.traverse(child => {

      if (typeof (child.material) != "undefined") {
        this.SearchForLeafes(child);
        this.SearchForTress(child);
        if (child.material.name.includes("Grass")) {
          this.grassController.AddGrassObj(child);
        }
        this.SearchForTress(child);
      }

    });
  }

  SearchForLeafes(child){
    if (child.material.name.includes("Leafes")) {
      child.material = this.leavesMat.GetMaterial(child.material.clone());
      //child.name = "Leafes";
    }
  }

  SearchForTress(child) {
    if (child.material.name == "Tree") {
      this.treeController.AddTreeObj(child);
    }
  }

}

export default MaterialController;