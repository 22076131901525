import { Object3D } from "three/build/three.module";
import IConfigObject from "./IConfigObject";

class Fascade implements IConfigObject {

    Models: Object3D[] = [];
    ConfigGroups =  ["fascade"];
    Xr: any;
    Store: any;

    constructor(xr: any, store:any){
      this.Xr = xr;
      this.Store = store;
      this.Init();
      this.Models = [];
    }

    Init(){
      this.Store.watch(state => state.area.fascade,this.HandleConfigChange);
    }

    HandleConfigChange = (newVal, oldVal)=>{
      
        this.Models.forEach(model=>{
          var enabled = model.userData.ConfigValue == newVal;
          model.visible =  model.userData.ConfigValue == newVal;

        });
    }

    AddModels(model: Object3D): void {

      this.Models.push(model);
        
      this.HandleConfigChange("Fascade", null);



    }

    GetModels(): Object3D[] {
      return this.Models;
    }
}

export default Fascade;