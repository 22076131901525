
import { PMREMGenerator,  SphereGeometry, MeshBasicMaterial, Mesh,  Texture, EquirectangularReflectionMapping, BackSide } from 'three';

class NeutralSky {
  
  skyTexture = null;
  c = null;

  constructor(props) {

    this.store = props.store;
    this.xr = props.xr;
    this.Skybox = null;

    this.canvas = this.CreateCanvas();

    this.skyTexture = new Texture(this.canvas);
    this.skyTexture.mapping = EquirectangularReflectionMapping;
    this.skyTexture.needsUpdate = true;

    var geometry = new SphereGeometry(100, 100, 20);
    var material = new MeshBasicMaterial({ 
      color : 0xF3F3F3,
      //map: this.skyTexture, 
      side: BackSide,
      depthTest : false,
      toneMapped : false
    });

    this.Skybox = new Mesh(geometry, material);
    this.Skybox.name = "skybox";
    this.Skybox.userData.noClip = true;
    //this.Skybox.visible = false;
    const ScenesNames = [ 
      "Solarzelle",
      "TinyCity",
      "Basement",
      "Kitchen",
      "LivingRoom",
      "InstallationsRoom",
      "Hallway",
      "UndergroundGarage",
    ]

    ScenesNames.forEach(name => {
      this.xr.SceneController.AddToScene(name, this.Skybox.clone());
      this.xr.SceneController.AddSkyToScene(name, this);
    });

    this.generator = new PMREMGenerator(this.xr.Renderer.instance);

    this.rt = this.generator.fromEquirectangular(this.skyTexture);

    return this;
  }

  AddEnvMapObject(obj) {
    this.envObjects.push(obj);
  }

  CreateCanvas() {
    const canvas = document.createElement("canvas");
    canvas.width = 256;
    canvas.height = 256;
    canvas.style.position = "fixed";
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.display = "none";
    canvas.classList.add("sky-texture");
    document.body.appendChild(canvas);
    this.c = canvas.getContext("2d");

    this.SetColor();

    return canvas;
  }


  SetColor = () => {
    if (this.c === null) { return; }

    var grd = this.c.createLinearGradient(0, 0, 0, 256);

    grd.addColorStop(0, "#ffffff");
    grd.addColorStop(1, "#ffeebb");

    // grd.addColorStop(0, "#ff0000");
    // grd.addColorStop(1, "#ff0000");
    
    // Fill with gradient
    this.c.fillStyle = grd;

    // Fill with gradient
    //this.c.fillStyle = "#ffffff";
    this.c.fillRect(0, 0, 256, 256);


    this.c.fillStyle = "#aaaaaa";
    let count = 10;
    let gapFactor = .3;

    for(var y = 0;y < count;y++){
      this.c.fillRect(0, 256 / count * y, 256 , (256 / 10) * gapFactor);
    }
    for(var x = 0;x < count;x++){
      this.c.fillRect(256 / count * x, 0, (256 / 10) * gapFactor, 256);
    }

    if (this.skyTexture) {

      this.skyTexture.needsUpdate = true;

    }
  }
}

export default NeutralSky;