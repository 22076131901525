import Image360 from '../gallery/solarzentrum_360.jpg';
import {SphereBufferGeometry, MeshBasicMaterial, Mesh} from 'three';
import webXRScene from '../webXRScene/src/index';

class Gallery360{

  pointer = false;
  pointerDiff = {
    x : 0,
    y : 0
  }
  pointerStartPos = {
    x : 0,
    y : 0
  }
  constructor(context){
    this.sphere = null;
    this.xr = new webXRScene(context.domElement);
    

    this.xr.Events.addEventListener("OnAnimationLoop", this.animate);


    window.addEventListener("pointerdown", this.pointerDown);
    window.addEventListener("pointermove", this.pointerMove);
    window.addEventListener("pointerup", this.pointerUp);

    this.Init();
  }
  pointerDown = (e)=>{
    this.pointer = true;
    this.pointerStartPos = {
      x : e.pageX,
      y : e.pageY
    };
  }
  pointerMove = (e)=>{
    if(!this.pointer){return;}
    this.pointerDiff = {
      x : this.pointerStartPos.x - e.pageX,
      y : this.pointerStartPos.y - e.pageY
    }
  }
  pointerUp = ()=>{
    this.pointer = false;
    this.pointerStartPos = {
      x : 0,
      y : 0
    };
  }

  Init = () =>{
    

    this.xr.Camera.instance.position.set(0,0,0);
  
    this.xr.CustomTextureLoader.load(Image360).then((texture)=>{

      var geometry = new SphereBufferGeometry( 10, 60, 40 );
      // invert the geometry on the x-axis so that all of the faces point inward
      geometry.scale( - 1, 1, 1 );

      var material = new MeshBasicMaterial({
        map : texture
      });

      this.sphere = new Mesh( geometry, material );
      this.sphere.rotation.y = -90 * Math.PI / 180;

      this.xr.Scene.add( this.sphere );


    });

    this.xr.Controls.ChangeToStatic();

    this.Hide();
    
  }

  Destroy = () =>{
    this.xr.Events.removeEventListener("OnAnimationLoop" , this.animate);

    window.removeEventListener("pointerdown", this.pointerDown);
    window.removeEventListener("pointermove", this.pointerMove);
    window.removeEventListener("pointerup", this.pointerUp);
  }

  Show = () =>{
    this.xr.Scene.visible = true;//add( this.sphere );
  }

  Hide = () =>{
    this.xr.Scene.visible = false;//.remove( this.sphere );
  }

  animate = () =>{
    if(this.sphere != null){
      if(!this.pointer){
        this.pointerDiff.x = Math.abs(this.pointerDiff.x) < .2 ? 0 : this.pointerDiff.x *= .8;
        this.pointerDiff.y = Math.abs(this.pointerDiff.y) < .2 ? 0 : this.pointerDiff.y *= .8;
      }
      if(this.pointerDiff.x + this.pointerDiff.y == 0){
        this.sphere.rotation.y += .001;
      }else{
        this.sphere.rotation.y += (this.pointerDiff.x * .00001);
      }
    }
  }
}

export default Gallery360;
