var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-3d-icon"},[(_vm.name == 'batterie')?[_c('div',{class:{
        small: _vm.room == _vm.Room.House,
        big: _vm.room == _vm.Room.ApartmentBuilding,
      }},[_c('BatteryIcon'),_vm._v("Stromspeicher ")],1)]:_vm._e(),(_vm.name == 'verbraucher')?[_c('div',{staticClass:"small"},[_c('Kueche'),_vm._v("Eigenverbrauch")],1)]:_vm._e(),(_vm.name == 'stromzaehler')?[_c('div',{class:{
        small: _vm.room == _vm.Room.House,
        big: _vm.room == _vm.Room.ApartmentBuilding,
      }},[_c('StromzaehlerIcon'),_vm._v("Stromzähler")],1)]:_vm._e(),(_vm.name == 'pv')?[_c('div',{class:{
        small: _vm.room == _vm.Room.House,
        big: _vm.room == _vm.Room.ApartmentBuilding,
      }},[_c('Solarmodul'),_vm._v("PV")],1)]:_vm._e(),(_vm.name == 'wechselrichter')?[_c('div',{class:{
        small: _vm.room == _vm.Room.House,
        big: _vm.room == _vm.Room.ApartmentBuilding,
      }},[_c('Batterie'),_vm._v("Wechselrichter")],1)]:_vm._e(),(_vm.name == 'stromnetz')?[_c('div',{class:{
        small: _vm.room == _vm.Room.House,
        big: _vm.room == _vm.Room.ApartmentBuilding,
      }},[_c('Strommast'),_vm._v("Stromnetz")],1)]:_vm._e(),(_vm.name == 'Stromnetz')?[_c('div',{staticClass:"big"},[_c('Strommast'),_vm._v("Stromnetz")],1)]:_vm._e(),(_vm.name == 'Solar')?[_c('div',{staticClass:"big"},[_c('Strommast'),_vm._v("Solar")],1)]:_vm._e(),(_vm.name == 'mieter')?[_c('div',{staticClass:"big"},[_c('Strommast'),_vm._v("Wohnungsstrom")],1)]:_vm._e(),(_vm.name == 'betriebsstrom')?[_c('div',{staticClass:"big"},[_c('Strommast'),_vm._v("Betriebsstrom")],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }