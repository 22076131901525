import {DoubleSide,MeshStandardMaterial,MeshNormalMaterial,MeshBasicMaterial,MeshPhongMaterial,MeshMatcapMaterial,TextureLoader} from "three/build/three.module";
import matcapMat from '../matcap_textures/soft.jpg';



const MaterialLibrary = {
  clay_haus: new MeshStandardMaterial({
    color : 0xd3d3d3,
    side : DoubleSide
  }),
  Menu: new MeshStandardMaterial({
    color : 0xaaaaaa,
    metalness : 0,
    roughness : .7
  }),
  Fenster: new MeshPhongMaterial( { 
    color: 0xccddff,
    transparent: true,
    opacity : .4,
    refractionRatio: 0.98, 
    reflectivity: 0.9 
  }),
  //  new MeshStandardMaterial({
  //   color : 0xffffff,
  //   metalness : 0,
  //   roughness : 0,
  //   transparent: true,
  //   opacity : .4
  // }),
 
 
  clay_wood: new MeshBasicMaterial({
    color: 0x8bc34a
  }),
  clay_Island: new MeshStandardMaterial({
    color: 0xaaaaaa
  }),
  clay_grass: new MeshStandardMaterial({
    color: 0xffffff
  }),
  clay_baum: new MeshStandardMaterial({
    color : 0xaaaaaa
  }),

  //Solarpanel
  Plane_baked : new MeshStandardMaterial({
    //skinning: true,
    roughness: .95
  }),
  Stromkabel: new MeshStandardMaterial({
    color: 0xFCA431
  }),
  Strom_bake: new MeshBasicMaterial({
    color: 0x838381
  }),
  Screen : new MeshBasicMaterial({
    name : "Screen"
  }),
}


MaterialLibrary.textures = {
    matcapTexture : new TextureLoader().load(matcapMat,(t)=>{

      Object.values(MaterialLibrary).map((mat,index)=>{
        if(mat.type=="MeshMatcapMaterial"){
          mat.matcap = t;
        }
      });
    })
  };

export default MaterialLibrary;