import IInteractiveObject from "./IInteractiveObject";
import Wallbox from './Wallbox';
import Boiler from './Boiler';
import Fridge from './Fridge';
import Stove from './Stove';
import Inverter from './Inverter';
import Battery from './Battery';
import Meter from './Meter';
import Pump from './Pump';
import Pumpinverter from './Pumpinverter';
import Car from './InteractiveCar';

import { MeshBasicMaterial , BackSide} from 'three'

//ApartmentBuilding
import HW_Bell from './HW_Bell';
import HW_Elevator from './HW_Elevator';
import HW_Light from './HW_Light';

import IR_Battery from './IR_Battery';
import IR_Inverter from './IR_Inverter';
import IR_Pump from './IR_Pump';

import LR_Light from './LR_Light';
import LR_TV from './LR_TV';
import LR_Vacuum from './LR_Vacuum';

import UG_Car from './UG_Car';
import UG_Wallbox from './UG_Wallbox';



interface IActiveObjects {
  [Name: string]: IInteractiveObject
}
class InteractiveObjectController {
  interactiveObjects: IActiveObjects

  //quick fix ≠ Store<State> => muss den store als eigenen type beschrieben haben 
  //=> siehe https://next.vuex.vuejs.org/guide/typescript-support.html
  store: any;
  router: any;

  idleMaterial: any = new MeshBasicMaterial({color : 0xF9C697, side: BackSide})
  hoverMaterial: any = new MeshBasicMaterial({color : 0xF38D2F, side: BackSide})
  selectionMaterial: any = new MeshBasicMaterial({color : 0xce6300, side: BackSide})

  constructor(props: any) {
    this.store = props.store;
    this.router = props.router;

    this.interactiveObjects = {
      "Wallbox": new Wallbox(props),
      "Boiler": new Boiler(props),
      "Fridge": new Fridge(props),
      "Stove": new Stove(props),
      "Inverter": new Inverter(props),
      "Battery": new Battery(props),
      "Meter": new Meter(props),
      "Car": new Car(props),
      "Pump": new Pump(props),
      "Pumpinverter": new Pumpinverter(props),

      //ApartmentBuilding
      "HW_Bell" : new HW_Bell(props),
      "HW_Elevator" : new HW_Elevator(props),
      "HW_Light" : new HW_Light(props),
      "IR_Battery" : new IR_Battery(props),
      "IR_Inverter" : new IR_Inverter(props),
      "IR_Pump" : new IR_Pump(props),
      "LR_Light" : new LR_Light(props),
      "LR_TV" : new LR_TV(props),
      "LR_Vacuum" : new LR_Vacuum(props),
      //"UG_Car" : new UG_Car(props),
      //"UG_Wallbox" : new UG_Wallbox(props),
    }


    this.store.watch(state => state.content.currentPOI, this.HandlePOIChange);
    this.store.watch(state => state.content.currentSubTab, this.HandleSubTabChange);

  }

  OnBeforeCompile(material){
    material.onBeforeCompile = (shader) => {

      shader.vertexShader = shader.vertexShader.replace(
        '#include <fog_vertex>',
        '#include <fog_vertex> \n vec4 pos = modelViewMatrix * vec4( position + normal * .01 , 1.0 );\n gl_Position = projectionMatrix * pos;\n'
      );

      material.shader = shader;
      material.needsUpdate = true;

    };
  }

  HandlePOIChange = (newValue, oldValue) => {
    //handle nulls
    if (newValue === null) { }
    if (oldValue === null) { }

    //Deactivate old subtabs first
    oldValue?.SubTabs?.map(subTab => {
      var iOChecker = Object.keys(this.interactiveObjects).includes(subTab.SceneObject?.InteractiveObjectName);

      if (iOChecker) {
        this.interactiveObjects[subTab.SceneObject?.InteractiveObjectName].OnExitScene();
        
      }
    });

    //Activate new subtabs
    newValue?.SubTabs?.map(subTab => {
      var iOChecker = Object.keys(this.interactiveObjects).includes(subTab.SceneObject?.InteractiveObjectName);

      if (iOChecker) {
        this.interactiveObjects[subTab.SceneObject?.InteractiveObjectName].OnEnterScene();
        
      }
    });


    //console.log("poi Changed: InteractiveObjectController" , newValue, oldValue);

  }

  SetStateOfObject = (state, obj) => {
    //console.log(state,obj);

    switch (state) {
      case "idle":
        this.Idle(obj);
        break;
      case "hovered":
        this.Hover(obj);
        break;
      case "selected":
        this.Select(obj);
        break;
    }

  }

  Idle = (obj) => {
    
    if(obj.userData.selected || obj.userData.hovered || !obj.userData.idle){
      obj.userData.selected = false;
      obj.userData.hovered = false;
      obj.userData.idle = true;
      if(obj.userData.hasOwnProperty("modelCopy")){
        obj.userData.modelCopy.traverse(el => {
          if(el.type == "Mesh"){
            el.material = this.idleMaterial.clone();
            this.OnBeforeCompile(el.material)
          }
        })
      }
    }
  }

  Hover = (obj) => {

    //if (obj.userData.outlineMaterial.shader == undefined) { return }
    if(obj.userData.idle || obj.userData.selected){
      obj.userData.selected = false;
      obj.userData.idle = false;
      obj.userData.hovered = true;
      if(obj.userData.hasOwnProperty("modelCopy")){
        
        obj.userData.modelCopy.traverse(el => {
          if(el.type == "Mesh"){
            el.material = this.hoverMaterial.clone()
            this.OnBeforeCompile(el.material)
          }
        })
      }
    }
      
    // obj.userData.outlineMaterial.shader.uniforms.color.value = new Color(0xF38D2F);
    // obj.userData.outlineMaterial.shader.uniformsNeedUpdate = true;

  }

  Select = (obj) => {
    if (obj.userData.selected) { return; }
      obj.userData.selected = true;
      obj.userData.idle = false;
      obj.userData.hovered = false;



    if(obj.userData.hasOwnProperty("modelCopy")){
      
      obj.userData.modelCopy.traverse(el => {
        if(el.type == "Mesh"){
          el.material = this.selectionMaterial.clone()
          this.OnBeforeCompile(el.material)
        }
      })
    }

    //wenn bereits aktiv dann unterbreche hier
    if (this.store.state.content.currentSubTab != null && this.store.state.content.currentSubTab.SceneObject != null) {
      if (this.store.state.content.currentSubTab.SceneObject.InteractiveObjectName == obj.userData.CMS_Reference) { return; }
    }

    //Verhindert das versehentliche "durchklicken" auch wenn das object nicht sichtbar ist
    if (obj.userData.Room != this.store.state.world.mainScene.xr.Scene.name) { return; }

    if (this.router.currentRoute.name != "Explorer" && this.router.currentRoute.name != "ExplorerSingle") { return; }

    // obj.userData.outlineMaterial.shader.uniforms.color.value = new Color(0xF38D2F);
    // obj.userData.outlineMaterial.shader.uniformsNeedUpdate = true;
    //obj.userData.outlineMaterial.color.setHex("#ff0000");//.uniformsNeedUpdate = true;

    var refName = this.GetRefName(obj);

    var poi = this.store.state.content.currentPOI;

    if (poi == null) {

      var selectedPOI = this.store.state.content.poi.find((p) => {

        var selected = p.SubTabs.find(el => {
          if (el.SceneObject == null) { return false; }

          return el.SceneObject.InteractiveObjectName == refName;
        });
        if (typeof (selected) == "undefined" || selected == null) {
          return false;
        } else {
          return true;
        }
      });

      if (typeof (selectedPOI) != "undefined") {

        this.router.push({ path: '/desktop/' + this.router.currentRoute.params.area + '/explore/' + selectedPOI.id})

        /*this.store.dispatch("content/SetCurrentPOI", selectedPOI);*/
        poi = selectedPOI;
      }
      //return;
    }


    var selected = poi.SubTabs.find(el => {
      if (el.SceneObject == null) { return false; }
      return el.SceneObject.InteractiveObjectName == refName;
    });

    if (typeof (selected) != "undefined" && selected != null) {
      this.store.dispatch("content/SetSubTab", selected);
    }

  }

  GetRefName = (obj) => {
    var refName = obj.userData.CMS_Reference;
    //refName = refName === "ECar" ? "Car" : refName;

    return refName;
  }



  HandleSubTabChange = (newSubTab, oldSubTab) => {
    //handle nulls
    if (newSubTab === null) { }
    if (oldSubTab === null) { }

    var checkForActiveObjectInOld = Object.keys(this.interactiveObjects).includes(oldSubTab?.SceneObject?.InteractiveObjectName);
    //console.log(checkForActiveObjectInOld);

    if (checkForActiveObjectInOld) {
      this.interactiveObjects[oldSubTab.SceneObject.InteractiveObjectName].OnDeselect();
    }

    var checkForActiveObjectInNew = Object.keys(this.interactiveObjects).includes(newSubTab?.SceneObject?.InteractiveObjectName);
    //console.log(checkForActiveObjectInNew);

    if (checkForActiveObjectInNew) {
      this.interactiveObjects[newSubTab.SceneObject.InteractiveObjectName].OnSelect();
    }

    //console.log("subtab Changed: InteractiveObjectController" , newSubTab, oldSubTab);

  }

  AddInteractiveObjects = (stack) => {

    Object.keys(this.interactiveObjects).map(iObj => {

      if (stack.hasOwnProperty(iObj)) {


        var cms_ref = stack[iObj].scene.userData.CMS_Reference;

        cms_ref = cms_ref == "ECar" ? "Car" : cms_ref;

        if (cms_ref === iObj) {
          

          stack[iObj].scene.traverse(child => {
            if (child.type == "Mesh" || child.type == "SkinnedMesh" && !child.name.includes("_copy")) {
              child.isClickEnabled = true;
              //stack[iObj].scene.userData.outlineMaterial = this.interactiveObjects[iObj].OutlineMaterial;
              stack[iObj].scene.userData.idle = false;
              stack[iObj].scene.userData.selected = false;
              stack[iObj].scene.userData.hovered = false;
              child.setState = e => this.SetStateOfObject(e, stack[iObj].scene);
              this.store.state.world.mainScene.xr.Controls.interactivityEnabled = true;
              this.store.state.world.mainScene.xr.Controls.ActiveObjects.push(child);
            }
          });

          this.interactiveObjects[iObj].AddModels(stack[iObj].scene);
          // console.log("AddInteractiveObjects" , iObj, stack[iObj].scene, this.store.state.world.mainScene.xr);


        }
      }

    });
  }

}

export default InteractiveObjectController;

