import { Object3D, Mesh ,MeshBasicMaterial,BackSide} from 'three';
import IInteractiveObject from "./IInteractiveObject";
import OutlineMaterial from './OutlineMaterial';

class Car implements IInteractiveObject {
  CMSReference: String = "Car";
  Models: Object3D[] = [];
  Xr: any;
  store: any;
  OutlineMaterial: any = new OutlineMaterial();
  modelCopy: any = null;

  constructor(props: any) {
    this.Xr = props.xr;
    this.store = props.store;
    this.modelCopy = null;
  }

  AddModels(model: Mesh): void {
    var modelCopy = model.clone();
        modelCopy.name = model.name + "_copy";
       
    model.userData.modelCopy = modelCopy;
    model.add(modelCopy);
    this.Models.push(modelCopy);
    this.Models.push(model);    

  }
  GetModels(): Object3D[] {
    throw new Error('Method not implemented.');

  }
  OnEnterScene(): void {
    //console.log("%c Enter Scene where Car is present", "color: #fff;background:green;");
  }
  OnExitScene(): void {
    // console.log("%c Exit Scene where Car is present", "color: #fff;background:orange;");
  }

  OnSelect(): void {
    console.log("%c Select Car Subtab", "color: #000;background:lightgreen;");
  }
  OnDeselect(): void {
    //console.log("%c DeSelect Car Subtab", "color: #000;background:tomato;");
  }

  OnHover(arg: any): void {

    // this.modelCopy.material.uniforms.linewidth.value = 2.0;
    // this.modelCopy.material.uniformsNeedUpdate = true;


  }
  OnRenderUI(arg: any): void {
    throw new Error('Method not implemented.');
  }

}

export default Car;