import { AnimationMixer, Clock, LoopOnce } from 'three';
import Utility from './../utils';


class AnimationController {
  constructor(props) {
    this.store = props.store;
    this.xr = props.xr;
    this.panels = null;
    this.isPlaying = false;
    this.progress = 0;
    this.mesh = null;
    this.animation = null;
    this.clipAction = null;
    this.resetAtEnd = true;

    this.xr.Events.registerEvent("OnResetAnimationController");
    
  }

  SetAnimation(mesh, animation, resetAtEnd = true) {
    this.ResetAnimation();


    this.mesh = mesh;
    this.animation = animation;
    this.resetAtEnd = resetAtEnd;

    this.mixer = new AnimationMixer(this.mesh);
    this.clipAction = this.mixer.clipAction(this.animation);
    this.clipAction.clampWhenFinished = true;
    this.clipAction.loop = LoopOnce;
    this.clipAction.timeScale = 1;

    this.clock = new Clock();
  }


  Play = () => {
    //this.mixer.setTime(0);
    this.clipAction.play();
    this.clock.getDelta();
    this.xr.Events.addEventListener("OnAnimationLoop", this.Animate);
    this.store.commit("animation/SetStatus", true);
    this.isPlaying = true;
  }


  Pause = () => {
    this.xr.Events.removeEventListener("OnAnimationLoop", this.Animate);
    this.store.commit("animation/SetStatus", false);
    this.isPlaying = false;
  }

  ResetAnimation = () => {
    if(this.clipAction === null){return;}
    
    if(this.animation.name == "PVGruen_Montage"){
      this.store.state.world.mainScene.rooms.ApartmentBuilding.ResetGrasPatch();
    }

    this.clipAction.time = 0;
    this.mixer.setTime(0);
    this.progress = 0;
    this.Pause();
    this.mixer.stopAllAction();
    this.store.commit("animation/SetProgress", 0);

    this.xr.Events.dispatchEvent("OnResetAnimationController",null)

  }

  Animate = () => {
    if(this.clipAction == null){
      return;
    }

    console.log("Animate AnimationController");

    this.progress = (this.clipAction.time / this.animation.duration);


    if(this.animation.name == "PVGruen_Montage"){
      this.store.state.world.mainScene.rooms.ApartmentBuilding.HandleGrasPatch(this.progress);
      //console.log(this.clipAction.time, this.store.state.world.mainScene.rooms.ApartmentBuilding.HandleGrasPatch(this.progress), this.store.state.area.current);
    }
    
    if (this.progress === 1 && this.resetAtEnd) {
      this.ResetAnimation();
    } else {
      this.store.commit("animation/SetProgress", this.progress);
    }

    this.store.state.world.mainScene.xr.Renderer.instance.shadowMap.needsUpdate = true;

    var delta = this.clock.getDelta();

    if (this.mixer) this.mixer.update(delta);
  }

}

export default AnimationController;