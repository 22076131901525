class TreeController {
    xr: any;
    store: any;
    baumMaterial: any;
    treeObjs: any;
    constructor(props: any) {
        this.xr = props.xr;
        this.store = props.store;
        this.treeObjs = [];
        this.baumMaterial = null;
        this.store.watch(state => state.area.season, (newValue, oldValue) => {
            this.ChangeTreeMaterial(newValue);
        });
    }

    ChangeTreeMaterial(newVal){
        this.treeObjs.forEach(tree => {
            tree.material.shader.uniforms.snowMultiplier.value = newVal == "Winter" ? 1 : 0.0;
        });
        
    }

    AddTreeObj(tree){
        
        if(this.baumMaterial == null){
            this.baumMaterial = tree.material;
            this.baumMaterial.name = "baumstamm";    
            this.baumMaterial.onBeforeCompile = shader => {
                shader.uniforms.snowMultiplier = { value: 0.0 };
                shader.fragmentShader = 'uniform float snowMultiplier;\n' + shader.fragmentShader;
                shader.fragmentShader = shader.fragmentShader.replace(
                    '#include <dithering_fragment>',
                    [
                        '#include <dithering_fragment>',
                        'gl_FragColor.rgb += mix(vec3(0.,0.,0.), vec3(snowMultiplier,snowMultiplier,snowMultiplier), max(vNormal.y, .2) );'
                    ].join('\n'));
    
                this.baumMaterial.shader = shader;
            }
        }       

        tree.material = this.baumMaterial;

        this.treeObjs.push(tree);
    }


}
export default TreeController